import { useQuery } from "react-query"
import { request } from "../../utils/axios-utils"
import moment from 'moment';

const getDashboardData = (data, type = 'campaign') => {
  return request({ url: `/partner-rating/${type}/history?from=${data.from}&to=${data.to}&limit=${data.limit}&page=${data.page}` })
}


export const useDashboardData = (page, type) => {
  const to = moment().utc().format();
  const from = moment().subtract(14, 'd').utc().format()
  return useQuery(['dashboard-data', page, type], () => getDashboardData({ from: from, to: to, limit: 10, page: page }, type))
}

