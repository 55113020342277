import "./styles.css";

const Spinner = ({ height = "20", thikness = "8" }) => {
    return (
        <div>
            <div className="flex justify-center items-center">
                <div className={`loader ease-linear rounded-full
                border-${thikness} border-t-${thikness} border-gray-400 h-${height} w-${height}`}>
                </div>
            </div>
        </div>
    )
}

export default Spinner
