/* This is custom Rating component which accepts an onChange and id as prop
on change is a function which triggers when the rating value changes and 
sends rating as to the main handler onChange function declared initailly */

/* id prop is used to handle multiple Rating states in same component 
we can just pass different id and grab that again in main handler function
to detect which rating component has changed
see single voicemail rating screen for a quick example */

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StarIcon as FilledStar } from "@heroicons/react/solid";
import { StarIcon as EmptyStar } from "@heroicons/react/outline"

const Rating = props => {
    const [rating, setRating] = useState(
        () => props.rating
    );

    const handleClick = index => {
        setRating(index + 1);
    };

    const { onChange } = props;
    const id = props?.id;

    useEffect(() => {
        if (onChange && id) {
            onChange({ rating, id })
        }
        else if (onChange && !id) {
            onChange({ rating })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rating])

    return (
        <div className={`flex ${props?.className}`}>
            {
                [...Array(5)].map((_element, index) => (
                    (index + 1) <= rating ?
                        <FilledStar
                            className={`h-${props.height}  text-yellow-300 ${props.isEditable ? 'cursor-pointer' : null} `}
                            key={index}
                            onClick={props.isEditable ?
                                () => handleClick(index) :
                                null}
                        /> :
                        <EmptyStar
                            className={`h-${props.height}  text-yellow-300 ${props.isEditable ? 'cursor-pointer' : null}`}
                            key={index}
                            onClick={props.isEditable ?
                                () => handleClick(index) :
                                null}
                        />
                ))
            }
        </div>
    )
}

Rating.defaultProps = {
    isEditable: false,
    rating: 1,
    height: 8,
}

Rating.propTypes = {
    isEditable: PropTypes.bool,
    rating: PropTypes.number,
}

export default Rating
