import React from "react";
import { Redirect } from "react-router-dom";
import Rating from "../../components/Rating";
import FilledButton from "../../components/Button/FilledButton";
import { useDispatch, useSelector } from "react-redux";
import { useRateSalesrep } from "../../hooks/data/useRateSalesrep";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useRatingData } from "../../hooks/data/useRatingData";
import { Spin, Space } from "antd";
import { IconButton, Paper } from "@material-ui/core";
import { IoReloadCircleOutline } from "react-icons/io5";
import { resetCallStatus } from "../../actions/callStateAction";
import AudioPlayer from "../../components/AudioPlayer";
import {
  ASSESSMENT_CALL,
  TRAINING_CALL,
} from "../../constants/callStateConstants";
import Modal from "../../components/Modal";

// 1 -> confidence
// 2 -> clarity
// 3 -> Negotiation
// 4 -> objection handling

const RateCallScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [formData, setFormData] = React.useState({
    rating: [],
  });

  const {
    dialCallSid,
    callType = TRAINING_CALL,
    fromDashboard,
  } = queryString.parse(history.location.search);

  const [showConfirmCallStatusDialog, setShowConfirmCallStatusDialog] =
    React.useState(() => {
      return !fromDashboard;
    });

  const { incomming } = useSelector((state) => state.callStatus);

  React.useEffect(() => {
    if (dialCallSid) {
      const incompleteRating = JSON.parse(
        localStorage.getItem(`call-rating-${dialCallSid}`)
      );
      console.log({ incompleteRating });
      if (incompleteRating) {
        setFormData({ ...incompleteRating, dialCallSid });
      } else {
        setFormData((prev) => ({ ...prev, dialCallSid }));
      }
    }
  }, [dialCallSid]);

  React.useEffect(() => {
    console.log({ formData });
  }, [formData]);

  const getValue = (id, type) => {
    const foundItem = formData.rating.find(
      (element) => element.trainingParameterId === id
    );

    if (foundItem) {
      if (type === "rating") {
        return foundItem.value;
      }
      return foundItem.comment;
    }

    if (type === "rating") {
      return 1;
    }

    return "";
  };

  const {
    data: ratingData,
    isLoading: isRatingLoading,
    error: ratingError,
    isError: isRatingError,
    refetch: ratingRefetch,
  } = useRatingData(formData?.dialCallSid, callType);
  const { mutate, isLoading, isSuccess, isError, error } = useRateSalesrep();
  const duration = ratingData?.data?.callDetails?.callDuration;

  const handleReloadRecording = () => {
    if (formData?.dialCallSid) {
      ratingRefetch();
    }
  };

  const handleChange = (event, id) => {
    event.preventDefault();
    setFormData((prevFormData) => {
      const newRating = [];
      const idChecker = (i) => i.trainingParameterId === id;
      if (!prevFormData.rating.some(idChecker)) {
        newRating.push({
          trainingParameterId: id,
          value: 1,
          comment: event.target.value,
        });
      }
      prevFormData.rating.forEach((r) => {
        if (r.trainingParameterId === id) {
          newRating.push({ ...r, comment: event.target.value });
        } else {
          newRating.push(r);
        }
      });
      return { ...prevFormData, rating: newRating };
    });
  };

  const handleRatingChange = ({ rating, id }) => {
    setFormData((prevData) => {
      const newRating = [];

      const idChecker = (i) => i.trainingParameterId === id;

      if (!prevData.rating.some(idChecker)) {
        newRating.push({ trainingParameterId: id, value: rating, comment: "" });
      }

      prevData.rating.forEach((r) => {
        if (r.trainingParameterId === id) {
          newRating.push({ ...r, value: rating });
        } else {
          newRating.push(r);
        }
      });

      return { ...prevData, rating: newRating };
    });
  };

  const handleSubmit = () => {
    mutate({
      ...formData,
      salesRepId: ratingData?.data?.callDetails.salesRepId,
      callType,
    });
  };

  React.useEffect(() => {
    if (Number(duration?.minutes) === 0 && Number(duration?.seconds) < 5) {
      history.push("/dashboard");
      dispatch(resetCallStatus());
    }
  }, [dispatch, duration, history]);

  React.useEffect(() => {
    if (incomming) {
      localStorage.setItem(
        `call-rating-${dialCallSid}`,
        JSON.stringify(formData)
      );
    }
  }, [dialCallSid, formData, incomming]);

  React.useEffect(() => {
    if (isSuccess) {
      localStorage.removeItem(`call-rating-${dialCallSid}`);
    }
  }, [dialCallSid, isSuccess]);

  return (
    <>
      {isRatingError ? (
        <p className="text-red text-lg">{ratingError.message}</p>
      ) : null}
      <Modal
        showModal={showConfirmCallStatusDialog}
        setShowModal={setShowConfirmCallStatusDialog}
        dialCallSid={dialCallSid}
        callType={callType}
      />
      <Spin spinning={!isRatingLoading} size="large">
        {isSuccess ? (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { isSuccess: true },
            }}
          />
        ) : null}
        <>
          <Paper
            Paper
            style={{
              padding: "40px 40px 80px",
              margin: 0,
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <div>
              <div className="mt-2">
                {isError ? (
                  <div className="mb-3">
                    <p className="text-red-500 text-lg">{error.message}</p>
                  </div>
                ) : null}
                <p className="text-2xl">
                  {!isRatingLoading
                    ? `Rate the call  for ${
                        callType === ASSESSMENT_CALL
                          ? ""
                          : ratingData?.data?.callDetails.fName
                      }`
                    : "Rate the call "}
                </p>
              </div>
              <div className="my-4">
                <p className="font-semibold">Recorded audio</p>
                <Space style={{ display: "flex", alignItems: "center" }}>
                  {!isRatingLoading ? (
                    <AudioPlayer
                      url={ratingData?.data?.callDetails.recordedUrl}
                    />
                  ) : (
                    <div>
                      Please wait, Processing recorded audio. try after 30
                      seconds.
                    </div>
                  )}
                  <IconButton onClick={handleReloadRecording}>
                    <IoReloadCircleOutline color="#2c9666" />
                  </IconButton>
                </Space>
              </div>
              <div className="my-4">
                <p className="font-semibold">
                  Rate the Sales Person on these parameters
                </p>
                <div className="mt-2 grid grid-cols-5 items-center gap-4 overflow-scroll">
                  {!isRatingLoading
                    ? ratingData?.data.trainingParameters.map((param) => (
                        <>
                          <p className="text-lg text-gray-600 col-start-1 col-span-1">
                            {param.name}
                          </p>
                          <Rating
                            id={param.id}
                            onChange={handleRatingChange}
                            isEditable={true}
                            className="col-span-1 col-start-2"
                            rating={getValue(param.id, "rating")}
                          />
                          <textarea
                            className="form-textarea resize-none border-gray-300 py-2.5 px-3.5
                                  rounded-md shawdow-md focus:border-buttons
                                  focus:ring-buttons col-span-3 h-28 whitespace-pre-wrap"
                            name={param.name}
                            value={getValue(param.id)}
                            onChange={(e) => handleChange(e, param.id)}
                          />
                        </>
                      ))
                    : " Loading... "}
                </div>
                <FilledButton
                  className="font-semibold text-white my-2 w-56"
                  handleClick={handleSubmit}
                  loading={isLoading}
                >
                  Submit Feedback
                </FilledButton>
              </div>
            </div>
          </Paper>
        </>
      </Spin>
    </>
  );
};

export default RateCallScreen;
