import { useTimer } from "react-timer-hook";
import { useState } from "react"
import { useProvideCallFeedback } from '../../hooks/data/useProvideCallFeedback'

export default function Modal({
  showModal = false,
  setShowModal,
  dialCallSid,
  callType,
}) {
  const { mutate, isLoading } = useProvideCallFeedback();
  const [callSuccessful, setCallSuccessful] = useState(true)

  const time = new Date();
  time.setSeconds(time.getSeconds() + 10);

  const handleSubmit = () => {
    if (callSuccessful) {
      setShowModal(false)
    }
    else {
      mutate({ dialCallSid, connected: false, callType })
    }
  }

  const { seconds } = useTimer({
    expiryTimestamp: time, autoStart: true, onExpire: () => {
      if (callSuccessful) {
        setShowModal(false);
        return
      }
      mutate({ dialCallSid, connected: false, callType });
    }
  });

  const handleChange = e => {
    switch (e.target.value) {
      case 'yes':
        setCallSuccessful(true);
        break;
      case 'no':
        setCallSuccessful(false);
        break;
      default:
        setCallSuccessful(true);
      // throw new Error(`Unknow event target value: ${e.target.value}`);
    }
  }

  if (!showModal) {
    return null
  }

  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="relative w-auto my-6 mx-auto max-w-4xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Was Call Successful?
              </h3>
              <span className='text-2xl ml-3 mt-1 w-auto'>({seconds} Sec)</span>
            </div>
            <div className="relative p-6 flex-auto">
              <fieldset onChange={handleChange}>
                <div>
                  <input type="radio" id="yes" name="yes" value="yes" className='mr-1' checked={callSuccessful}
                  />
                  <label for="yes">Yes</label>
                </div>

                <div>
                  <input type="radio" id="no" name="no" value="no" className='mr-1' checked={!callSuccessful} />
                  <label for="no">No</label>
                </div>
              </fieldset>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                disabled={isLoading}
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
              <button
                className="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                {isLoading ? 'Loading...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}