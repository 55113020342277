import React from "react";
import Layout from "../../hoc/Layout";
import { useAllTypeformResponse } from "../../hooks/data/useTypeform";
import Spinner from "../../components/Spinner";
import SearchIcon from "@material-ui/icons/Search";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextField, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Linkify from "react-linkify";

function TypeformResponseScreen() {
  const { data, isLoading, isError, error } = useAllTypeformResponse();
  const [expanded, setExpanded] = React.useState(false);

  //   const text = "i am youtube, https://www.youtube.com/";
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [filteredData, setFilteredData] = React.useState([]);

  function search(e) {
    if (e.target.value === "") setFilteredData(data?.data.sort());
    else {
      let text = e.target.value.toLowerCase();

      const fData = [];

      data?.data.forEach((value) => {
        const res = String(value.companyName).toLowerCase();
        if (res.includes(text)) {
          fData.push(value);
        }
      });
      setFilteredData(fData);
    }
  }

  React.useEffect(() => {
    if (data?.data) {
      setFilteredData(data?.data);
    }
  }, [data]);

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} rel="noreferrer" target="_blank">
      {text}
    </a>
  );

  return (
    <Layout>
      {isError && error?.message}
      <div className="my-8 mx-8">
        <p className="text-2xl my-4">Companies</p>
        <div className="bg-white rounded-md px-6 py-3 grid grid-cols-5 gap-2 shadow-md w-full justify-items-center text-xl my-5">
          <TextField
            label="Company Name"
            variant="filled"
            onChange={search}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="bg-white rounded-md px-6 py-3 shadow-md w-full text-xl my-5">
          {isLoading ? (
            <Spinner />
          ) : Object.entries(filteredData).length === 0 ? (
            <p className="text-md my-2"> No companies</p>
          ) : (
            Object.entries(filteredData).map((d) => (
              <Accordion
                key={d[1]._id}
                expanded={expanded === d[1]._id}
                onChange={handleChange(d[1]._id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>{d[1].companyName}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    {d[1].questions.length === 0
                      ? "Not Filled Yet"
                      : d[1].questions.map((q, i) => (
                          <>
                            <p>
                              <span className="font-semibold">Question</span>:{" "}
                              {q.question}
                            </p>
                            <p>
                              <span className="font-semibold">Answer</span>:{" "}
                              <Linkify  componentDecorator={componentDecorator}>{q.answer}</Linkify>
                            </p>
                            <br />
                          </>
                        ))}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </div>
        <div className="mt-8"></div>
      </div>
    </Layout>
  );
}

export default TypeformResponseScreen;
