import React from "react";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute"
import { useSelector, useDispatch } from "react-redux"
import LoginScreen from "./screens/LoginScreen"
import DashboardScreen from "./screens/Dashboard"
import HistoryScreen from "./screens/History"
import VoicemailScreen from "./screens/Voicemail";
import SingleVoicemailScreen from "./screens/Voicemail/SingleVoicemail"
import IncommingCallModal from "./components/Modal/IncommingCallModal";
import ConnectedScreen from "./screens/ConnectedScreen"
import RateCallScreen from "./screens/RateCallScreen";
import TypeformScreen from "./screens/TypeformResponseScreen"
import { ErrorBoundary } from "react-error-boundary";
import { useTwilio } from "./hoc/Context/TwilioContext"
import useAuth from "./hooks/useAuth"
import NotificationHoc from "./hoc/Notification"
import {
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom";
import { getUserAndToken } from "./actions/customerActions"
import { Status, useStatus } from "./hoc/Status";

function App() {
  const { setStatus } = useStatus();
  const history = useHistory();
  const { isAuthed } = useAuth();
  const dispatch = useDispatch();
  const { setupDC } = useTwilio();

  const { incomming } = useSelector((state) => state.callStatus);

  React.useEffect(() => {
    dispatch(getUserAndToken());
  }, [dispatch])

  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (incomming) setStatus(Status.Ringing);
    if (!incomming) setStatus(Status.Available);
    setIsOpen(incomming);
  }, [incomming, setStatus])

  React.useEffect(() => {
    if (isAuthed) {
      setupDC();
    }
  }, [isAuthed, setupDC])

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert" className="h-screen w-screen flex items-center justify-center flex-col">
        <p className="text-red-500 text-2xl font-semibold">Something went wrong:</p>
        <pre className="">{error.message}</pre>
        <button
          onClick={resetErrorBoundary}
          className="bg-gray-400 px-4 py-2.5 rounded-full font-semibold hover:bg-gray-500"
        >
          Go back to Dashboard
        </button>
      </div>
    )
  }

  return (
    <>
      <Switch>
        <Route path="/" exact>
          <Redirect to="/login" />
        </Route>
        <Route path="/login">
          <LoginScreen />
        </Route>
        <Route path="/signup">
          <h1>Cannot Signup</h1>
        </Route>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            history.push("/dashboard")
          }}
        >
          <NotificationHoc>
            <ProtectedRoute path="/dashboard">
              <DashboardScreen />
            </ProtectedRoute>
            <ProtectedRoute path="/history">
              <HistoryScreen />
            </ProtectedRoute>
            <ProtectedRoute exact path="/voicemail">
              <VoicemailScreen />
            </ProtectedRoute>
            <ProtectedRoute exact path="/voicemail/:id">
              <SingleVoicemailScreen />
            </ProtectedRoute>
            <ProtectedRoute path="/call-connected">
              <ConnectedScreen />
            </ProtectedRoute>
            {/*<ProtectedRoute path="/assessment-call-connected">
              <ConnectedScreen />
            </ProtectedRoute>*/}
            <ProtectedRoute path="/rate-call">
              <RateCallScreen />
            </ProtectedRoute>
            <ProtectedRoute path="/companies">
              <TypeformScreen />
            </ProtectedRoute>
          </NotificationHoc>
        </ErrorBoundary>
      </Switch>
      <IncommingCallModal isOpen={isOpen} />
    </>
  );
}

export default App;
