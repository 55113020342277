import { useState, useEffect, useCallback } from "react"
import { Device } from "@twilio/voice-sdk"
import { useSelector, useDispatch } from "react-redux"
import {
  acceptCallAction, callDisconnectedBySalesrep, callIncommingAction,
  rejectCallAction, getSaleRepAndCampaignInfo, setCallSID, setCallDetails,
  getAssessmentCallInfo,
} from "../actions/callStateAction"
import { request } from "../utils/axios-utils"
import { TRAINING_CALL, ASSESSMENT_CALL } from '../constants/callStateConstants'

export const useTwilioHook = () => {
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const [tokenExpired, setTokenExpired] = useState(false)
  const [device, setDevice] = useState(null);
  const [deviceConfigured, setDeviceConfigured] = useState(false);
  const [isRegistered, setIsRegistered] = useState(false);
  const [call, setCall] = useState(null);
  const [dialCallSid, setDialCallSid] = useState(null)
  const dispatch = useDispatch();

  const codec = useSelector(state => state?.profileSettings?.callSettings?.codec)

  const { ongoing, duration = {} } = useSelector(state => state.callStatus)

  async function logger(event, dialCallSid) {
    await request({ url: `/partner-call-event`, method: "POST", data: { dialCallSid: dialCallSid, callEvent: event } })
  }

  async function setupDC() {
    console.log("Setting up DC !!!")
    try {
      const { data } = await request({ url: `${process.env.REACT_APP_V2_API}/call-detail/partner/token` })
      setToken(String(data.token));
    } catch (error) {
      console.log(error)
    }
  }

  const initializeDevice = useCallback(() => {
    console.log("Initializing device !!!")

    try {
      // console.log(token, "initializez function");
      const newDevice = new Device(String(token), {
        debug: true,
        answerOnBridge: true,
        codecPreferences: codec ? [codec] : ["pcmu", "opus"],
        tokenRefreshMs: '10000',
        closeProtection: "Call will be disconnected ?"
      });
      setDevice(newDevice);
      // console.log({ newDevice })
    } catch (error) {
      console.log(error)
      // throw new Error(error);
    }
  }, [codec, token])


  const handleDevice = useCallback(() => {
    console.log("under handleDevice")

    device.on("registered", () => {
      window.localStorage.setItem('canAccept', true)
      setIsRegistered(true);
    })

    device.on('unregistered', () => {
      setIsRegistered(false);
    })

    if (!isRegistered && device.state !== "registering") {
      device.register();
    }

    device.on("error", function (error) {
      const code = error.code;
      console.log("code >>> " + code + ": " + error.message)
      if ([31202, 31204, 31205, 31206, 31207, 20101].indexOf(code) > -1) {
        if (ongoing) {
          setTokenExpired(true);
        } else {
          setupDC();
        }
      } else {
        setError(error);
      }
    });


    device.on("incoming", (incommingCall) => {
      const callSid = incommingCall.parameters.CallSid;
      console.log("test", incommingCall.customParameters);
      setDialCallSid(callSid)
      if (window.localStorage.getItem('canAccept') === 'true') {
        setCall(incommingCall);
        dispatch({ type: "RESET_SALES_REP_INFO" })

        const dcName = incommingCall.customParameters.get("partnerName")
        const trainingParameters = incommingCall.customParameters.get("trainingParams")
        const callType = incommingCall.customParameters.get("type") ?? TRAINING_CALL

        if (callType === ASSESSMENT_CALL) {
          const assessmentMappingId = incommingCall.customParameters.get("assessmentMappingId")
          const script = incommingCall.customParameters.get("script")
          const partnerScript = incommingCall.customParameters.get("partnerScript")
          const callDuration = incommingCall.customParameters.get("callDuration")
          dispatch(getAssessmentCallInfo(assessmentMappingId))
          dispatch(setCallDetails({ dcName, trainingParameters, script, partnerScript, callDuration }))
        }
        else {
          const salesRepId = incommingCall.customParameters.get("salesRepId")
          const campaignMappingId = incommingCall.customParameters.get("campaignMappingId")
          const campaignName = incommingCall.customParameters.get("campaignName")
          dispatch(setCallDetails({ dcName, trainingParameters, campaignName }))
          dispatch(getSaleRepAndCampaignInfo(salesRepId, campaignMappingId))
        }

        dispatch(setCallSID(incommingCall.parameters.CallSid))
        dispatch(callIncommingAction(callType))
        window.localStorage.setItem('canAccept', false);

        logger("incoming", callSid)
      } else {
        incommingCall.reject();
        logger("busy(either on call or rating screen)", callSid)
      }

    })

    setDeviceConfigured(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, dispatch, isRegistered]);

  const handleCall = useCallback(() => {
    call.on("disconnect", () => {
      console.log({ duration })
      window.localStorage.setItem('canAccept', true);
      console.log("--- getting disconnect event from twilio ----")
      dispatch(callDisconnectedBySalesrep())
    })

    call.on("error", (error) => {
      window.localStorage.setItem('canAccept', true);
      // throw new Error(error);
    })

    call.on('mute', (isMuted, call) => {
      // isMuted is true if the input audio is currently muted 
      // i.e. The remote participant CANNOT hear local device's input

      // isMuted is false if the input audio is currently unmuted
      // i.e. The remote participant CAN hear local device's input

      isMuted ? console.log('muted') : console.log('unmuted');
    });

    call.on("cancel", () => {
      window.localStorage.setItem('canAccept', true);
      dispatch(rejectCallAction());
      logger("accepted by someone else", dialCallSid)
      // dispatch(callDisconnectedBySalesrep())
    })

    call.on("accept", (call) => {
      console.log('calll ->>>>> ', call)
      dispatch(acceptCallAction());
      logger("accepted call", dialCallSid)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call, dispatch]);

  function disconnectCall() {
    if (call) {
      window.localStorage.setItem('canAccept', true);
      call.disconnect();
      setCall(null);
      logger("call disconnected", dialCallSid)
    }
  }

  function muteCall() {
    console.log("tried muting")
    try {
      call.mute();
    } catch (e) {
      console.log("error while muting", e)
    }

  }

  function unMuteCall() {
    if (call) {
      call.mute(false);
    }
  }

  function isMuted() {
    if (call) {
      return call.isMuted();
    } return false;
  }

  function acceptCall() {
    if (call) {
      call.accept();
    }
  }

  function rejectCall() {
    console.log("rejecting call from parter side")
    if (call) {
      call.reject();
      window.localStorage.setItem('canAccept', true);
      dispatch(rejectCallAction())
      logger("rejected call", dialCallSid)
    }
  }

  window.disconnectCall = disconnectCall;
  window.setupDC = setupDC;

  // to register
  useEffect(() => {
    if (device && !deviceConfigured) {
      handleDevice();
    }
  }, [device, handleDevice, deviceConfigured])

  useEffect(() => {
    if (token && !device) {
      initializeDevice();
    }
  }, [token, initializeDevice, device])

  useEffect(() => {
    if (call) {
      handleCall()
    }
  }, [call, handleCall])

  useEffect(() => {
    if (!ongoing) {
      setTokenExpired(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenExpired, ongoing])

  return { setupDC, disconnectCall, muteCall, unMuteCall, isMuted, error, acceptCall, rejectCall }
}