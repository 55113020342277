import { useQuery } from "react-query"
import { request } from "../../utils/axios-utils"
import { ASSESSMENT_CALL } from '../../constants/callStateConstants'
// import axios from "axios";

const getRatingData = (dialCallSid, callType) => {
  if (callType === ASSESSMENT_CALL) {
    if (dialCallSid) {
      return request({ url: `/assessment-call-detail/dial-call-sid/${dialCallSid}` })
    }
  }
  if (dialCallSid) {
    return request({ url: `/call-detail/dial-call-sid/${dialCallSid}` })
  }
}


export const useRatingData = (dialCallSid, callType) => {
  return useQuery(['rating-data', dialCallSid], () => getRatingData(dialCallSid, callType))
}