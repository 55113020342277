import { useQuery } from "react-query"
import { request } from "../../utils/axios-utils"

const getDashboardInsights = () => {
    return request({ url: `/partner/insights` })
}


export const useDashboardInsights = () => {
    return useQuery('dashboard-insights', () => getDashboardInsights())
}

