import React from 'react'
import { Route, Redirect } from "react-router-dom";
import useAuth from "../../hooks/useAuth"

const ProtectedRoute = ({ ...path }) => {
    const { isAuthed } = useAuth();
    return isAuthed ? <Route {...path} /> : <Redirect to="/login" />;
}

export default ProtectedRoute;
