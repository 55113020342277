import { useQuery, useMutation, useQueryClient } from "react-query"
import { request } from "../../utils/axios-utils"
import { useSelector } from "react-redux"

const getVoicemailData = (customerId) => {
    return request({ url: `/v1/dummyCustomer/voicemail?id=${customerId}` })
}

const getSingleVoicemailData = (meetId) => {
    return request({ url: `/v1/dummyCustomer/voicemailDetail?meetId=${meetId}` })
}

const postVoicemailRating = (data) => {
    return request({ url: `v1/api/add/meetingDetail`, method: 'POST', data })
}

export const useVoicemailData = () => {
    const { customerInfo: { user: { _id: customerId } } } = useSelector(state => state.customerLogin)
    return useQuery(['voicemail-data', customerId], () => getVoicemailData(customerId))
}

export const useSingleVoicemailData = (meetId) => {
    return useQuery(['single-voicemail-data', meetId], () => getSingleVoicemailData(meetId))
}

export const usePostVoicemailRating = () => {
    const queryClient = useQueryClient();
    return useMutation(postVoicemailRating, {
        onSuccess: () => {
            queryClient.invalidateQueries('single-voicemail-data');
        }
    });
}
