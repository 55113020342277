import {
    CUSTOMER_LOGIN_REQUEST,
    CUSTOMER_LOGIN_SUCCESS,
    CUSTOMER_LOGIN_FAIL,
    CUSTOMER_LOGOUT,
    CUSTOMER_REGISTER_REQUEST,
    CUSTOMER_REGISTER_SUCCESS,
    CUSTOMER_REGISTER_FAIL,
    SET_CODEC
} from "../constants/customerConstants"

export const customerLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case CUSTOMER_LOGIN_REQUEST:
            return { loading: true }
        case CUSTOMER_LOGIN_SUCCESS:
            return { loading: false, customerInfo: action.payload }
        case CUSTOMER_LOGIN_FAIL:
            return { loading: false, error: action.payload }
        case CUSTOMER_LOGOUT:
            return {}
        default:
            return state;
    }
}

export const customerRegisterReducer = (state = {}, action) => {
    switch (action.type) {
        case CUSTOMER_REGISTER_REQUEST:
            return { loading: true }
        case CUSTOMER_REGISTER_SUCCESS:
            return { loading: false, customerInfo: action.payload }
        case CUSTOMER_REGISTER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const profileSettingsReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_CODEC:
            switch (action.payload) {
                case 'pcmu':
                case 'opus':
                    return { ...state, callSettings: { ...state.callSettings, codec: action.payload } }
                default:
                    return { ...state, callSettings: { ...state.callSettings, codec: ["pcmu", "opus"] } }
            }
        default:
            return state
    }
}

