import { ChevronDownIcon, LogoutIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { IoSettingsSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { logout } from '../actions/customerActions';
import useAuth from '../hooks/useAuth';
import { useStatus } from '../hoc/Status';
import Avatar from './Avatar';

const Header = () => {
  const { customerInfo } = useSelector((state => state.customerLogin));
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const { status: partnerStatus } = useStatus();

  const { isAuthed } = useAuth();

  const [navInfos, setNavInfos] = useState([]);

  useEffect(() => {
    const getIsActive = (route) => {
      return route === location.pathname;
    };

    if (isAuthed) {
      setNavInfos([
        { text: 'Dashboard', link: '/dashboard', isActive: getIsActive('/dashboard') },
        // { text: 'voicemail', link: '/voicemail', isActive: getIsActive('/voicemail') },
        // { text: 'Earnings', link: '/earnings', isActive: getIsActive('/earnings') },
        { text: 'History', link: '/history', isActive: getIsActive('/history') },
        { text: 'Companies', link: '/companies', isActive: getIsActive('/companies') },
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleLogout = () => {
    console.log('loggin out')
    history.push('/');
    dispatch(logout());
  };

  const handleRoute = (link) => history.push(link);
  const handleShowDropdown = () => setShowDropdown(prevValue => (!prevValue));

  return (
    <div className='grid grid-cols-2 bg-backgrounds-medium'>
      <div className='px-4 py-3 h-20 w-64'>
        <img
          src={require('../assets/images/logo3.svg').default}
          alt=''
          className='h-full cursor-pointer text-gray-600'
          onClick={() => history.push('/login')}
        />
      </div>

      {/* Navigations */}
      <div className='flex items-center justify-end pr-3'>
        {
          customerInfo?.accessToken ?
            <>
              {/* <div className='ml-2'>
                                <select
                                    onChange={handleCodecChange}
                                    value={callSettings?.codec}
                                    placeholder='Select preferred codec'
                                >
                                    <option value=''>Select codec</option>
                                    <option value='pcmu'>PCMU</option>
                                    <option value='opus'>OPUS</option>
                                </select>
                            </div> */}
              {
                navInfos.map((singleInfo, index) => (
                  <button
                    className='text-lg mx-2'
                    onClick={() => handleRoute(singleInfo.link, index)}
                    key={singleInfo.link}
                  >
                    {singleInfo.text}
                    {
                      singleInfo.isActive
                        ? <div className='border-2 border-black' />
                        : null
                    }
                  </button>
                ))
              }
              <div className='ml-2'>
                <div className='flex items-center'>
                  {/* <SwitchToggle enabled={enabled} setEnabled={setEnabled} /> */}
                  <div>
                    {
                      {
                        0: "Unavailable",
                        1: "Available",
                        2: "Ringing",
                        3: "In Call",
                        4: "Writing Feeback",
                      }[partnerStatus]
                    }
                  </div>
                  {
                    customerInfo?.user.picURL ?
                      <Avatar img={customerInfo.user.picURL} /> :
                      <Avatar
                        text={customerInfo.user.fName.charAt(0).toUpperCase()}
                        textSize='xl'
                      />
                  }
                  <ChevronDownIcon className='text-black h-6 cursor-pointer' onClick={handleShowDropdown} />
                </div>
              </div>

              {
                showDropdown ?
                  <div className='rounded-lg absolute top-16 right-8 bg-white px-4 py-3 z-30 shadow-2xl'>
                    {/*<Link to='/profile-setting'>
                      <div className='flex'>
                        <IoSettingsSharp size={28} className='text-buttons' />
                        <p className='text-lg ml-2'>Profile Settings</p>
                      </div>
                    </Link>*/}

                    <button onClick={handleLogout}>
                      <div className='flex mt-3'>
                        <LogoutIcon className='text-buttons h-6' />
                        <p className='text-lg ml-3'>Logout</p>
                      </div>
                    </button>
                  </div> : null
              }
            </> :
            null
        }
      </div>
    </div>
  );
};

export default Header;
