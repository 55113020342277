import React from "react";
import Layout from "../../hoc/Layout";
import { PhoneIcon, StarIcon, DatabaseIcon } from "@heroicons/react/solid";
import Rating from "../../components/Rating";
import { convertDateTime } from "../../utils/reusable-functions";
import { useLocation } from "react-router-dom";
import { resetCallStatus } from "../../actions/callStateAction";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useDashboardInsights } from "../../hooks/data/useDashboardInsights";
import { useDashboardData } from "../../hooks/data/useDashboardData";
import { Pagination } from "@material-ui/lab";

const BigCard = ({ icon, title, body }) => {
  return (
    <div className="bg-white rounded-lg shadow-md">
      <div className="w-full ml-2 mt-2">{icon}</div>
      <p className="text-center font-semibold text-lg">{title}</p>
      <div className="text-center text-8xl mt-2 mb-8">{body}</div>
    </div>
  );
};

const RecentCallCard = ({
  salesRepName,
  dateTime,
  totalTime,
  rating,
  className,
  rate,
  callType,
}) => {
  return (
    <div
      className={`bg-white rounded-md px-6 py-3 grid grid-cols-5 gap-2 shadow-md w-full justify-items-center text-xl ${className} my-5`}
    >
      <div>{salesRepName}</div>
      <div>{dateTime}</div>
      <div>{totalTime}</div>
      {typeof rating === "number" && callType === "campaign" ? (
        <Rating rating={rating} />
      ) : (
        rating
      )}
      {callType === "assessment"
        ? rating === true
          ? "Liked 👍"
            ? rating === false
            : "Disliked 👎"
          : null
        : null}
      <div>{rate}</div>
    </div>
  );
};

const Dashboard = () => {
  const [callType, setCallType] = React.useState("campaign"); // assessment
  const [page, setPage] = React.useState(1);
  const {
    data: insightData,
    isLoading: isInsightLoading,
    error: insightError,
    isError: isInsightError,
  } = useDashboardInsights();
  const { data, isLoading, error, isError } = useDashboardData(page, callType);

  const handleChange = (_event, newPage) => {
    setPage(newPage);
  };
  const location = useLocation();

  const isSuccess = location.state?.isSuccess;

  const dispatch = useDispatch();

  const handleCallTypeChange = (type) => {
    setCallType(type);
  };

  React.useEffect(() => {
    if (isSuccess) {
      dispatch(resetCallStatus());
    }
  }, [dispatch, isSuccess]);

  return (
    <>
      {isInsightError ? (
        <p className="text-red text-lg">{insightError.message}</p>
      ) : null}
      {isError ? <p className="text-red text-lg">{error.message}</p> : null}
      <Layout>
        <div
          className="mx-8 my-8"
          onClick={() => console.log("main div triggred")}
        >
          <div className="grid grid-cols-4 gap-8 mt-2">
            <BigCard
              icon={<PhoneIcon className="text-buttons h-10" />}
              title="Total Calls Evaluated"
              body={
                <p className="text-5xl mt-5">
                  {isInsightLoading
                    ? "Loading..."
                    : `${insightData?.data?.totalCalls}`}
                </p>
              }
            />

            <BigCard
              icon={<StarIcon className="text-buttons h-10" />}
              title="Rating so far"
              body={
                <p className="text-5xl mt-5">
                  {isInsightLoading
                    ? "Loading..."
                    : `${insightData?.data?.avgRating}`}
                </p>
              }
            />

            <BigCard
              icon={<DatabaseIcon className="text-buttons h-10" />}
              title="Total Call Duration"
              body={
                <p className="text-4xl mt-5">
                  {isInsightLoading
                    ? "Loading..."
                    : `${insightData?.data?.totalDuration}`}
                </p>
              }
            />

            <BigCard
              icon={<DatabaseIcon className="text-buttons h-10" />}
              title="Average Call Duration"
              body={
                <p className="text-4xl mt-5">
                  {isInsightLoading
                    ? "Loading..."
                    : `${insightData?.data?.avgDuration}`}
                </p>
              }
            />
          </div>

          <div className="mt-6">
            {/*<select value={callType} onChange={handleCallTypeChange}>
              <option value="campaign">Campaign</option>
              <option value='assessment'>Assessment</option>
            </select>*/}

            <div className="flex gap-2 text-xl justify-end">
              <div
                className={`cursor-pointer ${
                  callType === "campaign"
                    ? "text-green-500 font-semibold border-b-2 border-green-500"
                    : ""
                }`}
                onClick={() => handleCallTypeChange("campaign")}
              >
                Campaign
              </div>
              <div
                className={`cursor-pointer ${
                  callType === "assessment"
                    ? "text-green-500 font-semibold border-b-2 border-green-500"
                    : ""
                }`}
                onClick={() => handleCallTypeChange("assessment")}
              >
                Assessment
              </div>
            </div>

            <p className="text-2xl my-4">
              Recent Calls <i className="text-sm">(last 14 days)</i>
            </p>
            {data?.data?.meta?.totalCount === 0 ? (
              <p className="text-xl my-2"> No calls made yet </p>
            ) : callType === "assessment" ? (
              <>
                <RecentCallCard
                  salesRepName={<p className="font-semibold">Candidate Name</p>}
                  dateTime={<p className="font-semibold">Call Details</p>}
                  totalTime={<p className="font-semibold">Duration</p>}
                  rating={<p className="font-semibold">Feedback</p>}
                  rate={<p className="font-semibold">Rate Call</p>}
                />
              </>
            ) : (
              <>
                <RecentCallCard
                  salesRepName={<p className="font-semibold">Salesrep Name</p>}
                  dateTime={<p className="font-semibold">Call Details</p>}
                  totalTime={<p className="font-semibold">Duration</p>}
                  rating={<p className="font-semibold">Rating</p>}
                  rate={<p className="font-semibold">Rate Call</p>}
                />
              </>
            )}
            {data?.data?.data.map((call, index) => (
              <RecentCallCard
                salesRepName={
                  call.salesRepFName
                    ? call.salesRepFName
                    : call.candidateName
                    ? call.candidateName
                    : "Unknown salesrep"
                }
                dateTime={convertDateTime(call.timeInitiated)}
                totalTime={`${Math.floor(call.callDuration / 60)} mins ${
                  call.callDuration % 60
                } sec`}
                callType={callType}
                rating={
                  call.rating
                    ? call.rating
                    : callType === "assessment"
                    ? "No Feedback"
                    : "Not yet rated"
                }
                key={index}
                rate={
                  call?.assessmentId ? (
                    <>
                      {call.avgRating ? (
                        <p>Already rated</p>
                      ) : (
                        <Link
                          to={`/rate-call?dialCallSid=${
                            call?.dialCallSid
                          }&callType=assessment&fromDashboard=${true}`}
                        >
                          <p className="text-blue-400 hover:text-blue-500">
                            {" "}
                            Rate Call
                          </p>
                        </Link>
                      )}
                    </>
                  ) : (
                    <>
                      {call.avgSalesRepRating ? (
                        <p>Already rated</p>
                      ) : (
                        <Link
                          to={`/rate-call?dialCallSid=${
                            call?.dialCallSid
                          }&fromDashboard=${true}`}
                        >
                          <p className="text-blue-400 hover:text-blue-500">
                            {" "}
                            Rate Call
                          </p>
                        </Link>
                      )}
                    </>
                  )
                }
              />
            ))}
          </div>
          {isLoading ? (
            " Loading... "
          ) : (
            <Pagination
              count={data?.data?.meta?.pageCount}
              onChange={handleChange}
              variant="outlined"
              shape="rounded"
              page={page}
            />
          )}
        </div>
      </Layout>
    </>
  );
};

export default Dashboard;
