import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js';

const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
const PUSHER_STATS_CHANNEL_KEY = process.env.REACT_APP_PUSHER_STATS_CHANNEL_KEY;
const PUSHER_STATS_CHANNEL_AUTH_ENDPOINT = `${process.env.REACT_APP_V2_API}/partner-status-log/pusher/auth/partner`;

export const Status = Object.freeze({
  Unavailable: 0,
  Available: 1,
  Ringing: 2,
  InCall: 3,
  WritingFeeback: 4,
});

const StatusContext = createContext();

export const useStatus = () => useContext(StatusContext);

export default function StatusProvider({ children }) {
  const channel = useRef();
  const interval = useRef();
  const location = useLocation();
  const callSid = useSelector(state => state?.callStatus?.meetId);
  const user = useSelector(state => state.customerLogin?.customerInfo);

  const since = useRef();
  const [status, setStatus] = useState(Status.Unavailable);

  // establish websocket connection to pusher
  useEffect(() => {
    if (!user?.accessToken) return;

    const pusher = new Pusher(PUSHER_STATS_CHANNEL_KEY, {
      cluster: PUSHER_CLUSTER,
      // using deprecated `authEndpoint` and `auth` options
      // because for some reasons `userAuthentication` options does not work
      authEndpoint: PUSHER_STATS_CHANNEL_AUTH_ENDPOINT,
      auth: { headers: { Authorization: `Bearer ${user.accessToken}` } },
    });

    const channelKey = 'presence-partner-status-log';
    channel.current = pusher.subscribe(channelKey);

    setStatus(Status.Available);

    return () => pusher?.unsubscribe(channelKey);
  }, [user]);

  // update status state according to the route path
  useEffect(() => {
    let s;
    switch (location.pathname) {
      case '/history':
      case '/dashboard':
      case '/companies':
        s = Status.Available;
        break;
      case '/call-connected':
        s = Status.InCall;
        break;
      case '/rate-call':
        s = Status.WritingFeeback;
        break;
      default:
        s = Status.Unavailable;
        break;
    }

    setStatus(s);
  }, [location]);

  // publish current state to pusher channel
  useEffect(() => {
    if (!channel?.current) return;
    since.current = Date.now();

    const triggerEvent = () => channel.current.trigger('client-status', {
      status,
      callSid,
      since: since.current
    });

    triggerEvent();

    clearInterval(interval.current);
    interval.current = setInterval(triggerEvent, 10000);
  }, [status, callSid]);

  return (
    <StatusContext.Provider value={{ status, setStatus }}>
      {children}
    </StatusContext.Provider>
  );
}
