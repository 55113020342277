import {
  SET_RATINGS_DETAILS,
  SET_RATINGS_LOADING,
  // SET_RATINGS_RECORDING_URL
} from "../constants/rateConstants";

const initialState = {
  loading: false,
  ratingInfo: '',
  // status: '',
  // recordedUrl: '',
};

export const rateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RATINGS_LOADING:
      return { ...state, loading: true };
    case SET_RATINGS_DETAILS:
      return { ...state, ratingInfo: action.payload };
    // case SET_RATINGS_RECORDING_URL:
    //   return { ...state, recordedUrl: action.payload };
    default:
      return state;
  }
};
