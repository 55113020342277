import { createContext, useContext } from "react"
import { useTwilioHook } from "../../hooks/useTwilioHook"

const TwilioContext = createContext();

export function useTwilio() {
  return useContext(TwilioContext);
}

export function TwilioProvider({ children }) {
  const {
    setupDC,
    disconnectCall,
    muteCall,
    unMuteCall,
    isMuted,
    acceptCall,
    rejectCall
  } = useTwilioHook();

  return (
    <TwilioContext.Provider value={{
      setupDC,
      disconnectCall,
      muteCall,
      unMuteCall,
      isMuted,
      acceptCall,
      rejectCall
    }}
    >
      {children}
    </TwilioContext.Provider>
  );
}
