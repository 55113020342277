import { useMutation } from "react-query";
import { request } from "../../utils/axios-utils";
import { useHistory } from "react-router-dom";

const postCallFeedback = (data) => {
  if (data.callType == "training") {
    return request({
      url: `/call-detail/call-connect-status`,
      method: "POST",
      data,
    });
  } else {
    return request({
      url: `/assessment-call-detail/call-connect-status`,
      method: "POST",
      data,
    });
  }
};

export const useProvideCallFeedback = () => {
  const history = useHistory();
  return useMutation(postCallFeedback, {
    onSuccess: () => {
      history.replace("/dashboard");
    },
  });
};
