import React, {
    useReducer,
    useRef,
    useEffect,
} from 'react';
import ReactPlayer from 'react-player'

import {
    PlayCircleOutlined,
    PauseCircleOutlined,
} from "@ant-design/icons"

const initialState = {
    url: null,
    playing: false,
    controls: false,
    light: false,
    volume: 1,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    seeking: false,
    ready: false,
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'set-url':
            return { ...state, url: action.payload }
        case 'ready':
            return { ...state, ready: true }
        case 'play':
            return { ...state, playing: true };
        case 'pause':
            return { ...state, playing: false };
        case 'stop':
            return { ...state, playing: false, url: null };
        case 'ended':
            return { ...state, playing: false }
        case 'toggle-mute':
            return { ...state, muted: !state.muted };
        case 'volume-change':
            return { ...state, volume: action.payload }
        case 'set-playback-rate':
            return { ...state, playbackRate: action.payload }
        case 'progress':
            return { ...state, ...action.payload }
        case 'set-duration':
            return { ...state, duration: action.payload };
        case 'seek-mouse-down':
            return { ...state, seeking: true };
        case 'seek-change':
            return { ...state, played: action.payload };
        case 'seek-mouse-up':
            return { ...state, seeking: false, played: action.payload };
        case 'reset-player-state':
            return { ...initialState }
        default:
            throw new Error(`Unknown action type ${action.type}`)
    }
}

const AudioPlayer = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const playerRef = useRef();

    useEffect(() => {
        // console.log('state >> ', state)
    }, [state])

    const handlePlay = () => {
        dispatch({ type: 'play' })
    }

    const handlePause = () => {
        dispatch({ type: 'pause' })
    }

    // const handleStop = () => {
    //     dispatch({ type: 'stop' })
    // }

    // const resetPlayerState = () => {
    //     dispatch({ type: 'reset-player-state' })
    // }

    const resetPlayerState = () => {
        dispatch({ type: 'reset-player-state' })
    }

    // const handleToggleMute = () => {
    //     dispatch({ type: 'toggle-mute' });
    // }

    const handleVolumeChange = e => {
        dispatch({ type: 'volume-change', payload: e.target.value });
    }

    const handleSetPlaybackRate = (e) => {
        dispatch({ type: 'set-playback-rate', payload: parseFloat(e.target.value) });
    }

    const handleOnPlaybackRateChange = (rate) => {
        dispatch({ type: 'set-playback-rate', payload: parseFloat(rate) });
    }

    const handleEnded = () => {
        dispatch({ type: 'ended' })
    }

    const handleSeekMouseDown = e => {
        dispatch({ type: 'seek-mouse-down' })
    }

    const handleSeekChange = e => {
        dispatch({ type: 'seek-change', payload: parseFloat(e.target.value) });
    }

    const handleSeekMouseUp = e => {
        dispatch({ type: 'seek-mouse-up', payload: parseFloat(e.target.value) })
        playerRef.current.seekTo(parseFloat(e.target.value));
    }

    const handleProgress = (progress) => {
        if (!state.seeking) {
            dispatch({ type: 'progress', payload: progress })
        }
    }

    const handleDuration = (duration) => {
        dispatch({ type: 'set-duration', payload: duration })
    }

    const handleOnReady = () => {
        dispatch({ type: 'ready' })
    }

    useEffect(() => {
        dispatch({ type: 'set-url', payload: props.url })

        return () => resetPlayerState();
    }, [props.url])

    if (!props.url) return (
        <p>Please try again after few seconds.</p>
    )

    return (
        <div>
            <ReactPlayer
                ref={playerRef}
                height="0"
                width="0"
                url={state.url}
                playing={state.playing}
                controls={state.controls}
                playbackRate={state.playbackRate}
                volume={state.volume}
                muted={state.muted}
                onPlay={handlePlay}
                onPause={handlePause}
                onPlaybackRateChange={handleOnPlaybackRateChange}
                onEnded={handleEnded}
                onProgress={handleProgress}
                onDuration={handleDuration}
                onReady={handleOnReady}
            />
            {state.ready ?
                <div className="w-96 p-4 shadow-md">
                    <div className="w-full grid grid-cols-8 justify-content items-center gap-4">
                        <div className="col-span-1">
                            {!state.playing ? <PlayCircleOutlined
                                style={{ fontSize: "40px", color: "gray" }}
                                onClick={handlePlay}
                            /> : <PauseCircleOutlined
                                style={{ fontSize: "40px", color: "gray" }}
                                onClick={handlePause}
                            />}
                        </div>
                        <div className="col-span-7">
                            <div className="relative">
                                <input
                                    className="w-full"
                                    style={{ accentColor: "#2e9b72" }}
                                    type='range' min={0} max={0.999999} step='any'
                                    value={state.played}
                                    onMouseDown={handleSeekMouseDown}
                                    onChange={handleSeekChange}
                                    onMouseUp={handleSeekMouseUp}
                                />
                                <span className="text-muted text-xs absolute right-1/2 top-5">
                                    {Math.floor(state.played * state.duration / 60)}:{`0${Math.round(state.played * state.duration % 60)}`.slice(-2)} / {Math.floor(state.duration / 60)}:{`0${Math.round(state.duration % 60)}`.slice(-2)}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex gap-8 justify-content items-center mt-2">
                        <div className="flex gap-2 items-center justify-content">
                            <label>Volume: </label>
                            <input
                                type='range'
                                min={0}
                                max={1}
                                step='any'
                                value={state.volume}
                                onChange={handleVolumeChange}
                                style={{ accentColor: "#2e9b72" }}
                            />
                        </div>
                        <div className="flex gap-2 items-center justify-content">
                            <label>Speed: </label>
                            <select
                                onChange={handleSetPlaybackRate}
                                value={state.playbackRate}
                                className="w-12"
                                style={{ "border": "none", "boxShadow": "0px 0px 1px grey", "padding": "3px", "borderRadius": "6px", }}
                            >
                                <option value={1}>1x</option>
                                <option value={2}>1.5x</option>
                                <option value={3}>2x</option>
                            </select>
                        </div>
                    </div>
                </div> :
                <>Reload using the icon, if its taking too long to load the audio...</>
            }
        </div>
    )
}

export default React.memo(AudioPlayer);