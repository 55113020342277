import {
  CALL_INCOMMING,
  CALL_ACCEPTED,
  CALL_REJECTED,
  CALL_TIMEOUT,
  CALL_HANGUP,
  CALL_DETAILS,
  SET_CALL_SID,
  RESET_SALES_REP_INFO,
  SALES_REP_INFO_LOADING,
  SALES_REP_INFO,
  CAMPAIGN_INFO,
  RATE_CALL_LOADING,
  RATE_CALL_SUCCESS,
  RATE_CALL_FAILED,
  CALL_DISCONNECTED_BY_SALESREP,
  RESET_CALL_STATUS,
} from "../constants/callStateConstants";

export const callStatusReducer = (state = {}, action) => {
  console.log("action", action);
  switch (action.type) {
    case CALL_INCOMMING:
      return { ...state, incomming: true, disconnectedBySalesrep: false, hangup: false, callType: action.payload };
    case CALL_ACCEPTED:
      return { ...state, incomming: false, accepted: true, ongoing: true };
    case CALL_HANGUP:
      return {
        ...state,
        accepted: false,
        hangup: true,
        incomming: false,
        ongoing: false,
        duration: action.payload,
      };
    case CALL_REJECTED:
      return { ...state, incomming: false, accepted: false, ongoing: false };
    case CALL_TIMEOUT:
      return { ...state, timeout: action.payload };
    case SET_CALL_SID:
      return { ...state, meetId: action.payload }
    case RESET_SALES_REP_INFO:
      return { ...state, salesRepLoading: false, salesRep: {}, campaign: {}, meetId: undefined };
    case SALES_REP_INFO_LOADING:
      return { ...state, salesRepLoading: action.payload };
    case SALES_REP_INFO:
      return { ...state, salesRep: action.payload };
    case CAMPAIGN_INFO:
      return { ...state, campaign: action.payload };
    case CALL_DISCONNECTED_BY_SALESREP:
      return { ...state, disconnectedBySalesrep: true, incomming: false };
    case RATE_CALL_LOADING:
      return { ...state, loading: true };
    case RATE_CALL_SUCCESS:
      return { ...state, loading: false, ratingSuccess: true };
    case RATE_CALL_FAILED:
      return {
        ...state,
        loading: false,
        ratingSuccess: false,
        errorMsg: action.payload,
      };
    case CALL_DETAILS:
      return {
        ...state,
        callDetails: action.payload
      }
    case RESET_CALL_STATUS:
      return {};
    default:
      return state;
  }
};
