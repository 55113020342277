export const CALL_INCOMMING = "CALL_INCOMMING";
export const CALL_ACCEPTED = "CALL_ACCEPTED";
export const CALL_REJECTED = "CALL_REJECTED";
export const CALL_TYPE = 'CALL_TYPE'

export const CALL_TIMEOUT = "CALL_TIMEOUT";
export const CALL_HANGUP = "CALL_HANGUP";

export const RESET_SALES_REP_INFO = "RESET_SALES_REP_INFO";
export const SALES_REP_INFO_LOADING = "SALES_REP_INFO_LOADING";
export const SALES_REP_INFO = "SALES_REP_INFO";
export const CAMPAIGN_INFO = "CAMPAIGN_INFO";
export const SET_CALL_SID = "SET_CALL_SID";
export const CALL_DETAILS = "CALL_DETAILS";

export const RATE_CALL_LOADING = "RATE_CALL_LOADING";
export const RATE_CALL_SUCCESS = "RATE_CALL_SUCCESS";
export const RATE_CALL_FAILED = "RATE_CALL_FAILED";
export const CALL_DISCONNECTED_BY_SALESREP = "CALL_DISCONNECTED_BY_SALESREP"

export const RESET_CALL_STATUS = "RESET_CALL_STATUS";

export const TRAINING_CALL = 'training'
export const ASSESSMENT_CALL = 'assessment'