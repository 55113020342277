import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

const useAuth = () => {
    const { customerInfo } = useSelector(state => state.customerLogin);

    const [isAuthed, setIsAuthed] = useState(() => {
        return customerInfo?.accessToken ? true : false;
    });

    useEffect(() => {
        if (customerInfo?.accessToken && !isAuthed) {
            setIsAuthed(true);
        } else if (!customerInfo?.accessToken && isAuthed) {
            console.log(false);
            setIsAuthed(false);
        }
    }, [isAuthed, customerInfo])

    return { isAuthed };
}

export default useAuth
