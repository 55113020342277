import React from 'react'
import Layout from "../../hoc/Layout"
import FilledButton from "../../components/Button/FilledButton"
import { useHistory } from 'react-router-dom'
import { useVoicemailData } from '../../hooks/data/useVoicemailData'
import Spinner from "../../components/Spinner"
import { convertDateTime } from "../../utils/reusable-functions"

const VoicemailCard = ({ email, datetime, duration, className, id }) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(`/voicemail/${id}`)
    }

    return (
        <div className={`w-4/5 grid grid-cols-4 bg-white shadow-md px-4 py-2.5 place-items-center rounded-xl ${className} text-lg`}>
            <div>
                {email}
            </div>
            <div>
                {convertDateTime(datetime)}
            </div>
            <div>
                {`${Math.floor(duration / 60)} min ${duration % 60} sec`}
            </div>
            <div>
                <FilledButton
                    handleClick={handleClick}
                    className="font-semibold w-28"
                >
                    View
                </FilledButton>
            </div>
        </div>
    )
}

const VoicemailScreen = () => {
    const { data, error, loading, isError } = useVoicemailData();

    return (
        <Layout>
            <div className="my-8 mx-8">
                <p className="text-2xl">Voicemails:</p>
                {data?.data?.length === 0 ? <p className="text-xl my-3">No voicemail available</p> : null}
                {loading ? <Spinner /> : null}
                {isError ? <p className="text-red-500 text-xl">{error}</p> : null}
                {data?.data?.map((singleData, index) => (
                    <div className="mt-4">
                        <VoicemailCard
                            key={index}
                            email={singleData.email}
                            datetime={singleData.createdAt}
                            duration={singleData.duration}
                            className="my-4"
                            id={singleData.meetId}
                        />
                    </div>
                ))}
            </div>
        </Layout>
    )
}

export default VoicemailScreen
