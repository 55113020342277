import Header from "../../components/Header.jsx"
import Navigation from "../Navigation"

const Layout = props => {
    return (
        <>
            <div className="relative h-screen">
                <div className="top-0 left-0 right-0 absolute">
                    <Navigation disabled={!props.navigation}>
                        <Header />
                        {props.children}
                    </Navigation>
                </div>
            </div>
        </>
    )
}

Layout.defaultProps = {
    navigation: true
}

export default Layout
