import React from 'react'

const Navigation = ({ children, disabled }) => {
    if (!disabled) {
        return children
    }
    return (
        <>
            <div className={`${disabled ? 'bg-white opacity-80 fixed inset-0 z-10' : null}`} />
            {React.Children.map(children, (child, index) => {
                return React.cloneElement(child, { className: `${child.props?.className} z-10 fixed` })
            })}
        </>
    )
}

Navigation.defaultProps = {
    disabled: false
}

export default Navigation
