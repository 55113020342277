import Spinner from "./../Spinner"

const FilledButton = ({ children, handleClick, className, type = "button", disabled = false, loading = false }) => {
    if (loading) {
        disabled = true;
    }
    return (
        <button
            className={`${className} ${disabled ? 'bg-backgrounds-light' : `bg-buttons border-2 border-buttons`} ${loading ? 'cursor-wait' : null} text-white px-4 py-2.5 rounded-full`}
            type={type}
            onClick={handleClick ? handleClick : null}
            disabled={disabled}
        >
            {loading ? <Spinner height='8' thikness='4' /> : children}
        </button>
    )
}

export default FilledButton
