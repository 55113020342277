import axios from "axios";
import store from "./../store"
import { updateUser, logout } from "../actions/customerActions"

const client = axios.create({ baseURL: process.env.REACT_APP_V2_API });

export const request = ({ ...options }) => {
    const accessToken = store.getState().customerLogin?.customerInfo?.accessToken;

    client.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    client.defaults.headers.post.ContentType = 'application/json';
    client.defaults.withCredentials = true;

    const onSuccess = response => response;

    const onError = (error) => {
        if (error.response.status === 401) {
            console.log("token expired here making request to get updated token");
            client
                .get(`${process.env.REACT_APP_V2_API}/token/partner`)
                .then(({ data }) => {
                    store.dispatch(updateUser(data));
                    return request({ ...options });
                })
                .catch((e) => {
                    store.dispatch(logout());
                });
        }
        return Promise.reject(error.response.data);
    };

    return client(options).then(onSuccess).catch(onError);
}