import { useMutation } from "react-query"
import { request } from "../../utils/axios-utils"
import { ASSESSMENT_CALL, TRAINING_CALL } from "../../constants/callStateConstants"

const postRating = (data, type = TRAINING_CALL) => {
  if (type === ASSESSMENT_CALL)
    return request({ url: `/assessment-candidate-rating`, method: 'POST', data })
  else
    return request({ url: `/sales-rep-rating/`, method: 'POST', data })
}

export const useRateSalesrep = () => {
  return useMutation((data) => {
    const { callType, ...rest } = data
    return postRating({ ...rest }, callType)
  });
}
