import {
  CALL_INCOMMING,
  CALL_ACCEPTED,
  CALL_REJECTED,
  CALL_TIMEOUT,
  CALL_HANGUP,
  CALL_DETAILS,
  SALES_REP_INFO_LOADING,
  SALES_REP_INFO,
  CAMPAIGN_INFO,
  SET_CALL_SID,
  CALL_DISCONNECTED_BY_SALESREP,
  RESET_CALL_STATUS,
} from "../constants/callStateConstants";
import { request } from "../utils/axios-utils"

export const callIncommingAction = (callType) => (dispatch) => {
  try {
    dispatch({
      type: CALL_INCOMMING,
      payload: callType
    });
  } catch (error) {
    console.error(error);
  }
};

export const acceptCallAction = () => (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    // acceptCall();
    dispatch({
      type: CALL_ACCEPTED,
    });
  } catch (error) {
    console.error(error);
  }
};

export const callDisconnectedBySalesrep = () => (dispatch) => {
  try {
    dispatch({
      type: CALL_DISCONNECTED_BY_SALESREP,
    });
  } catch (error) {
    console.error(error);
  }
};

export const hangupAcceptedCall = (duration) => (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    console.log("----  This is being disconnected from parter's app ----")
    window.disconnectCall?.()
    dispatch({
      type: CALL_HANGUP,
      payload: duration,
    });
  } catch (error) {
    console.error(error);
  }
};

export const rejectCallAction = () => (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    // rejectCall();
    dispatch({
      type: CALL_REJECTED,
    });
  } catch (error) {
    console.error(error);
  }
};

export const callTimeoutAction =
  ({ timeout }) =>
    (dispatch) => {
      try {
        dispatch({
          type: CALL_TIMEOUT,
          payload: timeout,
        });
      } catch (error) {
        console.error(error);
      }
    };

export const getSaleRepAndCampaignInfo = (salesRepId, campaignMappingId) => async (dispatch) => {
  try {
    dispatch({
      type: SALES_REP_INFO_LOADING, payload: true
    })
    const { data } = await request({
      url: `${process.env.REACT_APP_V2_API}/call-detail?salesRepId=${salesRepId}&campaignMappingId=${campaignMappingId}`
    })
    dispatch({
      type: SALES_REP_INFO_LOADING, payload: false
    })
    dispatch({
      type: SALES_REP_INFO, payload: data
    })
  }
  catch (error) {
    if (error.response) {
      dispatch({
        type: SALES_REP_INFO_LOADING,
        payload: false
      })
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
  }
}

export const getAssessmentCallInfo = (assessmentMappingId) => async (dispatch) => {
  try {
    dispatch({
      type: SALES_REP_INFO_LOADING, payload: true
    })
    const { data } = await request({
      url: `${process.env.REACT_APP_V2_API}/assessment-call-detail?assessmentMappingId=${assessmentMappingId}`
    })
    console.log("-------->", { data })
    dispatch({
      type: SALES_REP_INFO_LOADING, payload: false
    })
    dispatch({
      type: SALES_REP_INFO, payload: data
    })
  }
  catch (error) {
    if (error.response) {
      dispatch({
        type: SALES_REP_INFO_LOADING,
        payload: false
      })
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
  }
}

export const salesRepInfoAction = (salesRep) => ({
  type: SALES_REP_INFO,
  payload: salesRep,
});

export const campaignInfoAction = (campaign) => ({
  type: CAMPAIGN_INFO,
  payload: campaign,
});

export const setCallSID = (sid) => ({
  type: SET_CALL_SID,
  payload: sid,
})

export const resetCallStatus = () => (dispatch) => {
  try {
    console.log("reset call status >>>>>>>>>>>>>>>>>>>> ");
    dispatch({
      type: RESET_CALL_STATUS,
    });
  } catch (error) {
    console.error(error);
  }
};

export const setCallDetails = (data) => (dispatch) => {
  console.log({ data })
  try {
    console.log("set call details >>>>>>>>>>>", data)
    dispatch({
      type: CALL_DETAILS,
      payload: data
    });
  } catch (error) {
    console.error(error);
  }
}