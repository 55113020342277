import { useQuery } from "react-query"
import { request } from "../../utils/axios-utils"
import moment from 'moment'

// const def_to = moment().utc().format();
// const def_from = moment().subtract(7,'d').utc().format()

const getHistoryData = (data, type = 'campaign') => {
  return request({ url: `/partner-rating/${type}/history?from=${data.from}&to=${data.to}&limit=${data.limit}&page=${data.page}` })
}


export const useHistoryData = (from, to, page, type) => {
  from = from ? moment(from).utc().startOf().format() : moment().utc().startOf().format();
  to = to ? moment(to).utc().endOf().format() : moment().subtract(1, "month").utc().endOf().format();
  return useQuery(['history-data', from, to, page, type], () => getHistoryData({ from: from, to: to, limit: 10, page: page }, type))
}

