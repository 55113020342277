import { useReducer, useEffect } from "react"
import logo2 from "../assets/images/logo2.png"

const initialState = {
  isPermitted: false,
  permission: Notification.permission,
  currNotification: null,
}

function reducer(state, action) {
  switch (action.type) {
    case "notification-allowed":
      return {
        ...state,
        isPermitted: true
      }
    case "set-notification":
      return {
        ...state,
        currNotification: action.payload
      }
    case "remove-notification":
      return {
        ...initialState
      }
    default:
      throw new Error(`Invalid action type : ${action.type}`)
  }
}

function useBrowserNotification() {
  const [state, dispatch] = useReducer(reducer, initialState,);
  console.log({ state })

  function askPermission() {
    if (state.isPermitted) return;

    Notification.requestPermission(function (permission) {
      if (permission === "granted") {
        dispatch({ type: "notification-allowed" })
      }
    });
  }

  function showNotification({ title, body }) {
    if (document.visibilityState === "visible" || state.currNotification) {
      return;
    }

    let notification = new Notification(title, {
      body,
      icon: logo2,
      requireInteraction: true
    });

    dispatch({ type: "set-notification", payload: notification })

    notification.onclick = () => {
      closeNotification();
      window.parent.focus();
    }
  }

  function closeNotification() {
    if (state.currNotification) {
      state.currNotification.close();
      dispatch({ type: "remove-notification" })
    }
  }

  useEffect(() => {
    if (state.permission === 'granted') {
      dispatch({ type: "notification-allowed" })
    }
  }, [state.permission])

  return {
    state,
    askPermission,
    showNotification,
    closeNotification
  }

}

export default useBrowserNotification