import { useState } from 'react'
// import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import { login } from "../../actions/customerActions";

const Form = () => {
    const dispatch = useDispatch();

    const { loading } = useSelector((state => state.customerLogin))

    const [formData, setFormData] = useState({
        email: "",
        password: "",
    })

    const handleEmailChange = (e) => {
        setFormData(data => ({ ...data, email: e.target.value }))
    }

    const handlePasswordChange = (e) => {
        setFormData(data => ({ ...data, password: e.target.value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(login(formData));
    }

    return (
        <form onSubmit={handleSubmit} className="w-full mt-8">

            <div className="mt-6 w-full flex justify-center">
                <input
                    type="email"
                    placeholder="Username or Email"
                    value={formData.email}
                    onChange={handleEmailChange}
                    className="form-input w-3/5 border-gray-300 py-2.5 px-3.5
                    rounded-md shawdow-md focus:border-buttons focus:ring-buttons"
                />
            </div>

            <div className="mt-6 w-full flex justify-center">
                <input
                    type="password"
                    placeholder="Password"
                    className="form-input w-3/5 border-gray-300 py-2.5 px-3.5
                    rounded-md shawdow-md focus:border-buttons
                    focus:ring-buttons"
                    onChange={handlePasswordChange}
                />
            </div>

            {/* <div className="flex w-full justify-center mt-2">
                <div className="flex w-3/5 justify-end">
                    <Link
                        to=""
                    >
                        <p className="justify-end ">Forgot Password ?</p>
                    </Link>
                </div>
            </div> */}

            <div className="mt-6 w-full flex justify-center">
                <input
                    disabled={loading}
                    type="submit"
                    value={
                        loading ?
                            `Please wait loading...` :
                            `Sign In`
                    }
                    className="w-3/5 bg-buttons py-2 px-3 rounded-full"
                />
            </div>

        </form>
    )
}

export default Form
