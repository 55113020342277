import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Layout from "../../../hoc/Layout"
import Rating from "../../../components/Rating"
import { useSingleVoicemailData, usePostVoicemailRating } from "../../../hooks/data/useVoicemailData"
import { convertDateTime } from "../../../utils/reusable-functions"
import Spinner from "../../../components/Spinner"
import FilledButton from "../../../components/Button/FilledButton"

const SingleVoicemailScreen = () => {
    // make api call using id of voicemail
    const { id: meetId } = useParams();
    const { data, loading, isError, error } = useSingleVoicemailData(meetId);
    const history = useHistory();
    const { mutate, isLoading: submitLoading, isError: isSubmitError, error: submitError } = usePostVoicemailRating();

    const [formData, setFormData] = React.useState();

    React.useEffect(() => {
        if (data) {
            setFormData({
                confidenceRating: [1, ""],
                clarityRating: [1, ""],
                negotiationRating: [1, ""],
                objectionHandlingRating: [1, ""],
                dummyCustomerId: data?.data.dummyCustomerId,
                salesRepId: data?.data.salesRepId,
                campaignId: data?.data.campaignId,
                meetId,
                callDuration: `${Math.floor(data?.data?.callDuration / 60)} min ${data?.data?.callDuration % 60} sec`
            })
        }
    }, [data, meetId])

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData(prevData => {
            let newValue = prevData[name];
            newValue[1] = value;
            return { ...prevData, [name]: newValue }
        })
    }

    const handleRatingChange = ({ rating, id }) => {
        setFormData(prevData => {
            if (prevData) {
                let newValue = prevData[id];
                newValue[0] = rating;
                return { ...prevData, [id]: newValue }
            }
            return null
        })

    }

    const handleSubmit = () => {
        console.log(formData);
        mutate(formData)
    }

    return (
        <Layout>
            {isError ? <p className="text-red-500">{error}</p> : null}
            {loading ? <Spinner /> : null}
            {isSubmitError ? <p className="text-red-500">{submitError}</p> : null}
            {data ?
                <div className="my-8 mx-8">
                    <p
                        onClick={() => history.push("/voicemail")}
                        className="text-lg cursor-pointer">
                        {`< `} Go Back
                    </p>
                    <div className="flex items-baseline mt-2">
                        <p className="text-2xl">Voicemail</p>
                        <p className="mx-2 text-lg">{convertDateTime(data?.data.createdAt)}</p>
                    </div>
                    <audio controls className="my-2">
                        <source src={data?.data.callRecording} type="audio/ogg" />
                    </audio>
                    <div className="my-4 bg-white rounded-lg shadow-md w-3/5 px-3 py-3">
                        {data?.data.hasDummyCustomerRated ? <p className="font-semibold">Already rated</p> :
                            <p className="font-semibold">Rate the Sales Person on these parameters</p>
                        }

                        <div className="mt-2 grid grid-cols-3 items-center gap-4">
                            <p className="text-lg text-gray-600">Confidence</p>
                            <Rating
                                id="confidenceRating"
                                onChange={handleRatingChange}
                                rating={data?.data.hasDummyCustomerRated ? data?.data.confidenceRating[0] : null}
                                isEditable={data?.data.hasDummyCustomerRated ? false : true}
                            />
                            <textarea
                                className="form-textarea resize-y border-gray-300 py-2.5 px-3.5
                                rounded-md shawdow-md focus:border-buttons
                                focus:ring-buttons"
                                name="confidenceRating"
                                value={data?.data.hasDummyCustomerRated ? data?.data.confidenceRating[1] : formData?.confidenceRating[1]}
                                onChange={data?.data.hasDummyCustomerRated ? null : handleChange}
                                readOnly={data?.data.hasDummyCustomerRated}
                            />
                            <p className="text-lg text-gray-600">Clarity</p>
                            <Rating
                                id="clarityRating"
                                onChange={handleRatingChange}
                                rating={data?.data.hasDummyCustomerRated ? data?.data.clarityRating[0] : null}
                                isEditable={data?.data.hasDummyCustomerRated ? false : true}
                            />
                            <textarea
                                className="form-textarea resize-y border-gray-300 py-2.5 px-3.5
                                rounded-md shawdow-md focus:border-buttons
                                focus:ring-buttons"
                                name="clarityRating"
                                value={data?.data.hasDummyCustomerRated ? data?.data.clarityRating[1] : formData?.clarityRating[1]}
                                onChange={data?.data.hasDummyCustomerRated ? null : handleChange}
                                readOnly={data?.data.hasDummyCustomerRated}
                            />
                            <p className="text-lg text-gray-600">Negotiation</p>
                            <Rating
                                id="negotiationRating"
                                onChange={handleRatingChange}
                                rating={data?.data.hasDummyCustomerRated ? data?.data.negotiationRating[0] : null}
                                isEditable={data?.data.hasDummyCustomerRated ? false : true}
                            />
                            <textarea
                                className="form-textarea resize-y border-gray-300 py-2.5 px-3.5
                                rounded-md shawdow-md focus:border-buttons
                                focus:ring-buttons"
                                name="negotiationRating"
                                value={data?.data.hasDummyCustomerRated ? data?.data.negotiationRating[1] : formData?.negotiationRating[1]}
                                onChange={data?.data.hasDummyCustomerRated ? null : handleChange}
                                readOnly={data?.data.hasDummyCustomerRated}
                            />
                            <p className="text-lg text-gray-600">Objection Handling</p>
                            <Rating
                                id="objectionHandlingRating"
                                onChange={handleRatingChange}
                                rating={data?.data.hasDummyCustomerRated ? data?.data.objectionHandlingRating[0] : null}
                                isEditable={data?.data.hasDummyCustomerRated ? false : true}
                            />
                            <textarea
                                className="form-textarea resize-y border-gray-300 py-2.5 px-3.5
                                rounded-md shawdow-md focus:border-buttons
                                focus:ring-buttons"
                                name="objectionHandlingRating"
                                value={data?.data.hasDummyCustomerRated ? data?.data.objectionHandlingRating[1] : formData?.objectionHandlingRating[1]}
                                onChange={data?.data.hasDummyCustomerRated ? null : handleChange}
                                readOnly={data?.data.hasDummyCustomerRated}
                            />
                        </div>
                        {
                            data?.data.hasDummyCustomerRated ? null :
                                <FilledButton
                                    className="font-semibold text-white my-2 w-56"
                                    handleClick={handleSubmit}
                                    loading={submitLoading}
                                >
                                    Submit Feedback
                                </FilledButton>
                        }

                    </div>
                </div>
                : null
            }
        </Layout>
    )
}

export default SingleVoicemailScreen
