export const getNoiceDateFormat = (date) => {
    const strDate = String(date);
    const month = strDate.slice(4, 7);
    const dateNum = strDate.slice(8, 10);
    const year = strDate.slice(13, 15);

    const extra = dateNum[1] === "1" ? "st" :
        dateNum[1] === "2" ? "nd" :
            dateNum[1] === "3" ? "rd" : "th";

    return `${dateNum}${extra} ${month} ${year}`;
}

export const getLastWeekDate = () => {
    let ourDate = new Date();
    let pastDate = ourDate.getDate() - 7;
    ourDate.setDate(pastDate);
    return ourDate;
}

export const convertDateTime = (newmongoDate) => {
    const mongoDate = new Date(newmongoDate);
    const time = mongoDate.toLocaleTimeString('en',
        { timeStyle: 'short', hour12: true, timeZone: 'UTC' });
    const year = mongoDate.getFullYear();
    const monthNumber = mongoDate.getMonth() + 1;
    const newdate = mongoDate.getDate();
    let month;

    switch (monthNumber) {
        case 1:
            month = "Jan";
            break;
        case 2:
            month = "Feb"
            break;
        case 3:
            month = "Mar"
            break;
        case 4:
            month = "Apr"
            break;
        case 5:
            month = "May"
            break;
        case 6:
            month = "Jun"
            break;
        case 7:
            month = "Jul"
            break;
        case 8:
            month = "Aug"
            break;
        case 9:
            month = "Sep"
            break;
        case 10:
            month = "Oct"
            break;
        case 11:
            month = "Nov"
            break;
        case 12:
            month = "Dec"
            break;
        default:
            month = "none"
    }

    return `${newdate} ${month} ${year} ${time}`
}