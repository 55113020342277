import { Redirect } from "react-router-dom"
import Form from "./Form";
// import BigWhiteButton from "../../components/Button/BigWhiteButton";
// import { FcGoogle } from "react-icons/fc"
import useAuth from "../../hooks/useAuth";

const LoginScreen = () => {
    const { isAuthed } = useAuth();

    // console.log("LoginScreen")

    return (
        <>
            {isAuthed ? <Redirect to="/dashboard" /> : null}
            <div className="grid grid-cols-2 h-screen">
                <div>
                    <div className="py-3 pl-4 bg-backgrounds-light">
                        <img
                            src={require("../../assets/images/logo3.svg").default}
                            alt=""
                            className="cursor-pointer h-auto w-56 overflow-hidden"
                            onClick={() => console.log("Hello")}
                        />
                    </div>

                    <div className="mt-8 flex flex-col items-center justify-center w-full">
                        <div className="w-3/5">
                            <p className="text-lg text-gray-500">Login
                                {/* or {" "}
                                <span>
                                    <Link className="hover:text-blue-500 text-blue-500 underline" to="/signup">
                                        Sign up
                                    </Link>
                                </span> */}
                            </p>
                            <p className="text-lg text-gray-500">in Dialworks</p>
                        </div>
                    </div>
                    <Form />
                    {/* <div className="flex flex-col w-full items-center mt-8 ">
                        <p className="text-gray-500 text-lg">Or </p>
                        <BigWhiteButton className="mt-8 flex">
                            <FcGoogle size={28} />
                            <p className="text-lg ml-3">Login with Google</p>
                        </BigWhiteButton>
                    </div> */}
                    <div className="w-full flex flex-col items-center mt-16 justify-center text-gray-700 font-semibold">
                        <p className="text-3xl italic mt-24">“There is only one boss - the</p>
                        <p className="text-3xl italic">customer”</p>
                    </div>
                </div>

                <div className="bg-backgrounds-medium">
                    <div className="grid grid-cols-6 mt-20 justify-items-center">
                        <img
                            src={require("../../assets/images/login-image1.png").default}
                            alt=""
                            className="col-start-2 col-span-3 row-span-full w-4/5"
                        />
                        <img
                            src={require("../../assets/images/login-image2.png").default}
                            alt=""
                            className="col-start-3 col-span-3 row-span-full mt-40 w-4/5"
                        />
                    </div>

                    <div className="mt-20">
                        <p className="text-5xl text-center text-gray-600 leading-10 font-semibold">Everything starts with the</p>
                        <p className="text-5xl text-center text-gray-600 leading-10 font-semibold mt-4">Customer</p>
                        <p className="text-xl text-center mt-12 italic">Help in coaching and training salespersons.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginScreen
