import useBrowserNotification from "../../hooks/useBrowserNotification";
import { useEffect } from "react"
import { useSelector } from "react-redux"

const NotificationHOC = ({ children }) => {
  const {
    state,
    askPermission,
    showNotification,
    closeNotification,
  } = useBrowserNotification();

  const { incomming, salesRep } = useSelector((state) => state.callStatus);

  const fName = salesRep?.salesRep?.fName;
  const companyName = salesRep?.companyDetail?.name;

  useEffect(() => {
    if (incomming && fName && companyName) {
      showNotification({
        title: `${fName} from ${companyName} is Calling`,
        body: "Click on this notification to directly go to Dialworks platform."
      })
    }
    else closeNotification();
  }, [closeNotification, incomming, fName, companyName, showNotification])

  useEffect(() => {
    if (!state.isPermitted) {
      askPermission();
    }
  }, [askPermission, state.isPermitted])

  return (
    <>
      {children}
    </>
  )
}

export default NotificationHOC