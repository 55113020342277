import {
    SET_SIGNUP_INFO_REQUEST,
    SET_SIGNUP_INFO_SUCCESS,
    SET_SIGNUP_INFO_FAIL
} from "../constants/SignupFormConstants";

export const signupFormInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case SET_SIGNUP_INFO_REQUEST:
            return { loading: true }
        case SET_SIGNUP_INFO_SUCCESS:
            return { loading: false, signupFormInfo: action.payload }
        case SET_SIGNUP_INFO_FAIL:
            return { loading: true, error: action.payload }
        default:
            return state;
    }
}