import { PhoneIncomingIcon, PhoneMissedCallIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useMicrophone from '../CustomHooks/useMicrophone/useMicrophone';
import { useTwilio } from './../../hoc/Context/TwilioContext';
import { ASSESSMENT_CALL } from '../../constants/callStateConstants'

const IncommingCallModal = ({ isOpen }) => {
  const { acceptCall, rejectCall } = useTwilio();
  const { allowPermission, havePermission } = useMicrophone();
  const { accepted, callType, salesRep } = useSelector(state => state.callStatus);
  const incommingCallData = useSelector(state => state.callStatus?.salesRep);

  const stopPropagation = (ev) => ev.stopPropagation();

  if (!isOpen) return null;

  return (
    <>
      {!havePermission ? () => allowPermission() : null}
      {accepted ? <Redirect to='/call-connected' /> : null}
      <div
        className='bg-white opacity-70 inset-0 fixed z-20'
        onClick={stopPropagation}
      />
      <div
        className='fixed top-1/2 left-1/2 bg-white
        rounded-3xl px-8 py-6 transform -translate-x-1/2
        -translate-y-1/2 shadow-2xl bg-opacity-100 max-w-2xl z-30'
        onClick={stopPropagation}
      >
        <p className='text-xl font-semibold text-black'>
          Incomming call in progress...
        </p>
        <div className='grid grid-cols-3 gap-4 mt-4 items-center'>
          <div className='col-start-1 col-span-2 justify-items-center place-content-center'>
            {callType === ASSESSMENT_CALL ?
              <>
                <div className='grid grid-cols-2 mt-3'>
                  <p className='text-lg'>Name:</p>
                  <p className='text-lg'>{salesRep?.name}</p>
                </div>
                <div className='grid grid-cols-2 mt-2'>
                  <p className='text-lg'>Company:</p>
                  <p className='text-lg'>{salesRep?.company}</p>
                </div>
                <div className='grid grid-cols-2 mt-3'>
                  <p className='text-lg'>Incoming call type: </p>
                  <p className='text-lg'>{callType}</p>
                </div>
                <div className='grid grid-cols-2 mt-2'>
                  <p className='text-lg'>Skills to practice:</p>
                  <div className='text-sm grid grid-cols-2 gap-2 '>
                    {salesRep?.trainingParameters?.map(
                      (param, index) => (
                        <span
                          key={index}
                          className='bg-backgrounds-light px-2 py-1 rounded-full w-min flex justify-center'
                        >
                          {param.name}
                        </span>
                      ))}
                  </div>
                </div>
              </> :
              <><div className='grid grid-cols-2 mt-3'>
                <p className='text-lg'>Name:</p>
                <p className='text-lg'>{incommingCallData?.salesRep?.fName}</p>
              </div>
                <div className='grid grid-cols-2 mt-2'>
                  <p className='text-lg'>Company:</p>
                  <p className='text-lg'>{incommingCallData?.companyDetail?.name}</p>
                </div>
                <div className='grid grid-cols-2 mt-2'>
                  <p className='text-lg'>Industry:</p>
                  <p className='text-lg'>{incommingCallData?.companyDetail?.industry}</p>
                </div>
                {/*<div className='grid grid-cols-2 mt-3'>
                  <p className='text-lg'>Incoming call type: </p>
                  <p className='text-lg'>{callType}</p>
                </div>*/}
                <div className='grid grid-cols-2 mt-2'>
                  <p className='text-lg'>Skills to practice:</p>
                  <div className='text-sm grid grid-cols-2 gap-2 '>
                    {incommingCallData?.campaign?.trainingParameters?.map((param, index) => (
                      <span
                        key={index}
                        className='bg-backgrounds-light px-2 py-1 rounded-full w-min flex justify-center'
                      >
                        {param.name}
                      </span>
                    ))}
                  </div>
                </div>
              </>}
          </div>
          <div className='ml-20'>
            <p className="text-xl mb-4 font-semibold">{`${callType === ASSESSMENT_CALL ? 'HIRING' : callType}`.toUpperCase()}</p>
            <img
              className='rounded-full h-28'
              src={require('../../assets/images/incomming_call.png').default}
              alt=''
            />
          </div>
        </div>
        <div className='w-full flex justify-center items-center justify-items-center mt-4 gap-4'>
          <PhoneIncomingIcon
            className='h-12 bg-green-500 hover:bg-green-700 cursor-pointer text-white rounded-full p-2.5'
            onClick={acceptCall}
          />
          <PhoneMissedCallIcon
            className='h-12 bg-red-500 hover:bg-red-700 cursor-pointer text-white rounded-full p-2.5'
            onClick={rejectCall}
          />
        </div>
      </div>
    </>
  );
};

export default IncommingCallModal;
