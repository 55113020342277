import { request } from "./../utils/axios-utils"
import store from "../store"

import {
  CUSTOMER_LOGIN_REQUEST,
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGIN_FAIL,
  CUSTOMER_LOGOUT,
  CUSTOMER_REGISTER_REQUEST,
  CUSTOMER_REGISTER_SUCCESS,
  CUSTOMER_REGISTER_FAIL,
  SET_CODEC
} from "../constants/customerConstants"

export const login = ({ email, password }) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_LOGIN_REQUEST
    })
    const { data } = await request({
      url: `${process.env.REACT_APP_V2_API}/partner/sign-in`,
      data: { email, password },
      method: 'POST'
    })
    dispatch({
      type: CUSTOMER_LOGIN_SUCCESS,
      payload: data
    })
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CUSTOMER_LOGIN_FAIL,
        payload: error.response.data
      })
    } else if (error.request) {
      console.log(error.request);
      dispatch({
        type: CUSTOMER_LOGIN_FAIL,
        payload: 'Could not log in'
      })
    } else {
      console.log('Error', error.message);
      dispatch({
        type: CUSTOMER_LOGIN_FAIL,
        payload: 'Could not log in'
      })
    }

  }
}

export const logout = (allDevice = false) => async (dispatch) => {
  const user = store.getState()?.customerLogin?.customerInfo?.user;

  if (user) {
    try {
      const { data } = await request({
        url: `${process.env.REACT_APP_V2_API}/partner/logout`,
        data: { userHash: user?.userHash, allDevice },
        method: 'POST'
      })
      if (data) {
        console.log(data)
        dispatch({
          type: CUSTOMER_LOGOUT
        })
      }
    }
    catch (error) {
      dispatch({
        type: CUSTOMER_LOGOUT
      })
    }
  }
}

export const register = ({ name, email, password, languages, industries, profExp, graduation, degree, specialisation, gender }) => async (dispatch) => {
  try {
    dispatch({
      type: CUSTOMER_REGISTER_REQUEST
    })
    const { data } = await request({
      url: `${process.env.REACT_APP_API}/v1/auth/signup/dummyCustomer`,
      data: {
        name, email, password, languages, industries, profExp, graduation, degree, specialisation, gender
      },
      method: 'POST',
    });
    dispatch({
      type: CUSTOMER_REGISTER_SUCCESS,
      payload: data
    })
    dispatch({
      type: CUSTOMER_LOGIN_SUCCESS,
      payload: data
    })
  } catch (error) {
    if (error.response) {
      console.log("error from action", error.response.data)
      dispatch({
        type: CUSTOMER_REGISTER_FAIL,
        payload: error.response.data
      })
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }
  }
}

export const getUserAndToken = () => async (dispatch) => {
  try {
    const { data } = await request({ url: `${process.env.REACT_APP_V2_API}/token/partner` })
    dispatch({
      type: CUSTOMER_LOGIN_SUCCESS,
      payload: data
    })
  } catch (error) {
    if (error.response) {
      dispatch({
        type: CUSTOMER_LOGIN_FAIL,
        payload: error.response.data
      })
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log('Error', error.message);
    }

  }
}

export const updateUser = (data) => dispatch => {
  dispatch({
    type: CUSTOMER_LOGIN_SUCCESS,
    payload: data
  })
}

export const setCodec = (codec) => (dispatch) => {
  try {
    dispatch({ type: SET_CODEC, payload: codec })
    const callSettings = JSON.parse(localStorage.getItem('callSettings'));
    localStorage.setItem('callSettings', JSON.stringify({ ...callSettings, codec }))
  } catch (error) {
    console.error(error)
  }
}