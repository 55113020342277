import { useEffect } from "react";
import { MdMicOff, MdMic, MdPhoneDisabled } from "react-icons/md";
import { useStopwatch } from "react-timer-hook";
import { useDispatch, useSelector } from "react-redux";
import { hangupAcceptedCall } from "../../actions/callStateAction";
import { Redirect } from "react-router-dom";
import { useTwilio } from "../../hoc/Context/TwilioContext";
import { ASSESSMENT_CALL } from "../../constants/callStateConstants";
import htmr from "htmr";

const ConnectedScreen = () => {
  const { muteCall, unMuteCall, isMuted } = useTwilio();

  const dispatch = useDispatch();

  const connectedScreenData = useSelector(
    (state) => state.callStatus?.salesRep
  );

  const callStatus = useSelector((state) => state.callStatus);

  const callType = useSelector((state) => state.callStatus.callType);

  const meetId = useSelector((state) => state.callStatus?.meetId);
  const salesRepLoading = useSelector(
    (state) => state.callStatus?.salesRepLoading
  );

  const isCallOngoing = useSelector((state) => state.callStatus?.ongoing);

  const dcName = useSelector((state) => state?.callStatus?.callDetails?.dcName);

  const { seconds, minutes, hours } = useStopwatch({ autoStart: true });

  const { disconnectedBySalesrep, hangup } = useSelector(
    (state) => state.callStatus
  );

  const hangupCall = () => {
    console.log("running from index.js");
    dispatch(hangupAcceptedCall({ minutes: minutes, seconds: seconds }));
  };

  useEffect(() => {
    if (disconnectedBySalesrep) {
      hangupCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disconnectedBySalesrep]);

  return (
    <>
      {hangup ? (
        <Redirect
          to={`/rate-call?dialCallSid=${meetId}&callType=${callType}`}
        />
      ) : isCallOngoing === false ? (
        <Redirect to="/dashboard" />
      ) : null}
      <div className="px-12 py-12 w-full h-screen border-2 overflow-y-scroll">
        <div className="grid grid-cols-2 gap-6 h-full overflow-y-scroll">
          <div className="grid grid-rows-4 h-full gap-4 overflow-y-scroll">
            <div className="row-start-1 row-span-1 h-full bg-backgrounds-light rounded-lg shadow-xl">
              <div className="grid grid-cols-2 px-4 py-4 h-full">
                <div className="place-self-start h-full flex flex-col justify-center px-3">
                  <div className="flex gap-2 mb-2">
                    <p className="text-xl font-semibold">
                      {`${
                        callType === ASSESSMENT_CALL ? "HIRING" : callType
                      }`.toUpperCase()}
                    </p>
                    {callType === ASSESSMENT_CALL &&
                      callStatus?.salesRep?.callDuration && (
                        <p className="font-semibold">
                          - Call duration: {callStatus?.salesRep?.callDuration}{" "}
                          mins
                        </p>
                      )}
                  </div>
                  <p className="text-3xl">
                    {`0${hours}`.slice(-2)}: {`0${minutes}`.slice(-2)}:{" "}
                    {`0${seconds}`.slice(-2)}
                  </p>
                  <p className="text-xl">Call in progress...</p>
                </div>
                <div className="flex justify-center items-center justify-self-end gap-3 px-3">
                  {!isMuted() ? (
                    <MdMic
                      className="text-white bg-green-500 hover:bg-green-600 rounded-full p-2 cursor-pointer"
                      size={42}
                      onClick={() => muteCall()}
                    />
                  ) : (
                    <MdMicOff
                      className="text-white bg-red-500 hover:bg-red-600 rounded-full p-2 cursor-pointer"
                      size={42}
                      onClick={() => unMuteCall()}
                    />
                  )}
                  <MdPhoneDisabled
                    className="text-white bg-red-500 hover:bg-red-600 rounded-full p-2 cursor-pointer"
                    size={42}
                    onClick={hangupCall}
                  />
                </div>
              </div>
            </div>
            {/*{connectedScreenData?.callType === ASSESSMENT_CALL ? <>ASSESSMENT_CALL</> : <>*/}
            <div className="row-start-2 row-span-3 shadow-xl bg-white rounded-lg px-4 py-4">
              <p className="font-semibold text-lg my-3">
                {callType === ASSESSMENT_CALL
                  ? "Candidate Details"
                  : "Sales Rep Details"}
              </p>
              <div className="grid grid-cols-3 gap-6">
                <div className="col-start-1 col-span-1">Name</div>
                <div className="col-start-2 col-span-2 flex items-center">
                  <p className="mx-4">:</p>
                  {callType === ASSESSMENT_CALL ? (
                    <>{callStatus?.salesRep?.name}</>
                  ) : (
                    <>
                      {connectedScreenData?.salesRep?.fName}{" "}
                      {connectedScreenData?.salesRep?.lName}
                    </>
                  )}
                </div>

                {callType === ASSESSMENT_CALL ? (
                  <>
                    {callStatus?.salesRep?.company && (
                      <>
                        <div className="col-start-1 col-span-1">
                          Company name
                        </div>
                        <div className="col-start-2 col-span-2 flex items-center">
                          <p className="mx-4">:</p>
                          {callStatus?.salesRep?.company}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <div className="col-start-1 col-span-1">Campaign Name</div>
                    <div className="col-start-2 col-span-2 flex items-center">
                      <p className="mx-4">:</p>
                      {connectedScreenData?.campaign?.name}
                    </div>

                    <div className="col-start-1 col-span-1">Company name</div>
                    <div className="col-start-2 col-span-2 flex items-center">
                      <p className="mx-4">:</p>
                      {connectedScreenData?.companyDetail?.name}
                    </div>
                  </>
                )}
                {callType === ASSESSMENT_CALL ? null : (
                  <>
                    <div className="col-start-1 col-span-1">Industry</div>
                    <div className="col-start-2 col-span-2 flex items-center">
                      <p className="mx-4">:</p>
                      {connectedScreenData?.companyDetail?.industry}
                    </div>
                  </>
                )}

                <div className="col-start-1 col-span-1">
                  Training Parameters
                </div>
                <div className="col-start-2 col-span-2 flex items-center">
                  <p className="mx-4">:</p>
                  {/* {trainingParameters} */}
                  {callType === ASSESSMENT_CALL ? (
                    <>
                      {callStatus?.salesRep?.trainingParameters?.map(
                        (param) => (
                          <span
                            key={param.id}
                            className="bg-backgrounds-light px-2 py-1 rounded-full w-min flex justify-center"
                          >
                            {param.name}
                          </span>
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {connectedScreenData?.campaign?.trainingParameters?.map(
                        (param, index) => (
                          <span
                            key={index}
                            className="bg-backgrounds-light px-2 py-1 rounded-full w-min flex justify-center"
                          >
                            {param.name}
                          </span>
                        )
                      )}
                    </>
                  )}
                </div>

                {callType === ASSESSMENT_CALL ? null : (
                  <>
                    <div className="col-start-1 col-span-1">Strengths:</div>
                    <div className="col-start-2 col-span-2 flex items-flex-start">
                      <p className="mx-4">:</p>
                      {connectedScreenData?.strength?.length > 0 ? (
                        <div className="flex flex-wrap gap-2 ">
                          {connectedScreenData?.strength.map((s, index) => (
                            <div
                              key={index}
                              className="bg-backgrounds-light px-2 py-1 rounded-full"
                            >
                              {s.name}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>NA</>
                      )}
                    </div>
                    <div className="col-start-1 col-span-1">Weakness:</div>
                    <div className="col-start-2 col-span-2 flex items-flex-start">
                      <p className="mx-4">:</p>
                      {connectedScreenData?.weakness?.length > 0 ? (
                        <div className="flex flex-wrap gap-2 ">
                          {connectedScreenData?.weakness.map((w, index) => (
                            <div
                              key={index}
                              className="bg-backgrounds-light px-2 py-1 rounded-full"
                            >
                              {w.name}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <>NA</>
                      )}
                    </div>
                  </>
                )}
                <div className="col-span-2">
                  <p>
                    Your name for this call:{" "}
                    <span className="font-semibold">{dcName}</span>
                  </p>
                </div>
              </div>
            </div>
            {/*</>}*/}
          </div>

          {/*{connectedScreenData?.callType === ASSESSMENT_CALL ? <>ASSESSMENT_CALL</> : <>*/}
          <div className="px-4 py-4 shadow-xl bg-white rounded-lg overflow-scroll h-full">
            {callType === ASSESSMENT_CALL ? (
              <>
                {salesRepLoading ? (
                  <div>Loading</div>
                ) : (
                  <>
                    {callStatus?.salesRep?.partnerScript && (
                      <>
                        <p className="font-semibold text-xl">Script</p>
                        <div className="showdow-lg px-3 py-3 mb-3">
                          <p style={{ fontSize: "13px" }}>
                            {htmr(
                              htmr(String(callStatus?.salesRep?.partnerScript))
                            )}
                          </p>
                        </div>
                      </>
                    )}
                    <p className="font-semibold text-xl">Questions</p>
                    {callStatus?.salesRep?.instructions?.length > 0 ? (
                      <>
                        {callStatus?.salesRep?.instructions.map((p, index) => {
                          return (
                            <div className="showdow-lg px-3 py-3 mb-3">
                              <p
                                className="font-semibold"
                                style={{ fontSize: "13px" }}
                              >
                                {index + 1}: Question: {p?.question}
                              </p>
                              <p
                                className="italic"
                                style={{ fontSize: "13px" }}
                              >
                                Instruction: {p?.instruction}
                              </p>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div>No questions were added</div>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {salesRepLoading ? (
                  <div>Loading</div>
                ) : (
                  <>
                    {connectedScreenData?.campaign?.playbook?.length > 0 ? (
                      <>
                        {connectedScreenData?.campaign?.playbook?.map(
                          (p, index) => {
                            return (
                              <div className="showdow-lg px-3 py-3 mb-3">
                                <p
                                  className="font-semibold"
                                  style={{ fontSize: "13px" }}
                                >
                                  {index + 1}: Question: {p?.question}
                                </p>
                                <p
                                  className="italic"
                                  style={{ fontSize: "13px" }}
                                >
                                  Instruction: {p?.instruction}
                                </p>
                              </div>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <div>No questions were added</div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectedScreen;
