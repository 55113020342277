import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { customerLoginReducer, customerRegisterReducer, profileSettingsReducer } from "./reducers/customerReducers"
import { signupFormInfoReducer } from "./reducers/SignupFormReducer"
import { callStatusReducer } from "./reducers/callStateReducer"
import { rateReducer } from "./reducers/rateReducer";

const reducer = combineReducers({
    customerLogin: customerLoginReducer,
    customerRegister: customerRegisterReducer,
    profileSettings: profileSettingsReducer,
    signupFormInfo: signupFormInfoReducer,
    callStatus: callStatusReducer,
    rating: rateReducer,
})

const customerInfoFromStorage = localStorage.getItem('customerInfo') ?
    JSON.parse(localStorage.getItem('customerInfo')) : null

const callSettingsFromStorage = localStorage.getItem('callSettings') ?
    JSON.parse(localStorage.getItem('callSettings')) : null

const middleware = [thunk];

const initialState = {
    customerLogin: { customerInfo: customerInfoFromStorage },
    profileSettings: { callSettings: callSettingsFromStorage },
};

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;