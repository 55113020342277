import React from 'react'
import Layout from "../../hoc/Layout"
import Rating from "../../components/Rating"
import { useHistoryData } from "../../hooks/data/useHistoryData"
import { convertDateTime } from "../../utils/reusable-functions"
import { TextField } from '@material-ui/core'
import moment from 'moment'
import { Pagination } from '@material-ui/lab'

const RecentCallCard = ({ salesRepName, dateTime, totalTime, rating, comments, className }) => {
  return (
    <div className={`bg-white my-4 rounded-md px-6 py-3 grid grid-cols-4 gap-2 shadow-md w-full justify-items-center text-xl ${className}`}>
      <div>{salesRepName}</div>
      <div>{dateTime}</div>
      <div>{totalTime}</div>
      {typeof rating === "number" ? <Rating rating={rating} /> : rating}
    </div>
  )
}

const HistoryScreen = () => {
  const [callType, setCallType] = React.useState('campaign') // assessment
  const [page, setPage] = React.useState(1)
  const [from, setFrom] = React.useState(null)
  const [to, setTo] = React.useState(null)
  const { data, isLoading, isError, error } = useHistoryData(from, to, page, callType);
  const handleChange = (_event, newPage) => {
    setPage(newPage)
  }
  const handleCallTypeChange = type => {
    setCallType(type)
  }
  React.useEffect(() => {
    setTo(moment().utc().format("YYYY-MM-DD"))
    setFrom(moment().utc().subtract(1, 'month').format("YYYY-MM-DD"))
  }, [])
  return (
    <Layout>
      {isError ? <p className="text-red-500">{error.message}</p> : null}
      <div className="my-8 mx-8">
        <p className="text-2xl my-4">History</p>
        <div className="flex gap-2 text-xl">
          <div>Call Type: </div>
          <div
            className={`cursor-pointer ${callType === 'campaign' ? 'text-green-500 font-semibold border-b-2 border-green-500' : ''}`}
            onClick={() => handleCallTypeChange('campaign')}
          >Campaign</div>
          <div
            className={`cursor-pointer ${callType === 'assessment' ? 'text-green-500 font-semibold border-b-2 border-green-500' : ''}`}
            onClick={() => handleCallTypeChange('assessment')}
          >Assessment</div>
        </div>
        <div className="bg-white rounded-md px-6 py-3 grid grid-cols-5 gap-2 shadow-md w-full justify-items-center text-xl my-5">
          <TextField
            label="From"
            type="date"
            value={moment(from).utc().format("YYYY-MM-DD")}
            onChange={(e) => {
              setFrom(moment(e.target.value, "YYYY-MM-DD").utc().format())
              // console.log(from)
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="To"
            type="date"
            value={moment(to).utc().format("YYYY-MM-DD")}
            onChange={(e) => {
              setTo(moment(e.target.value, "YYYY-MM-DD").utc().format())
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className="mt-8">

          {data?.data?.meta?.totalCount === 0 ? <p className="text-xl my-2"> No calls made yet </p> : <>
            <RecentCallCard
              salesRepName={<p className="font-semibold">Salesrep Name</p>}
              dateTime={<p className="font-semibold">Call Details</p>}
              totalTime={<p className="font-semibold">Duration</p>}
              rating={<p className="font-semibold">Rating</p>}
            />
          </>
          }
          {data?.data?.data.map((singleCall, index) => (
            <RecentCallCard
              salesRepName={singleCall.salesRepFName ? singleCall.salesRepFName : 'Unknown Salesrep'}
              key={index}
              dateTime={convertDateTime(singleCall?.timeInitiated)}
              totalTime={`${Math.floor(singleCall.callDuration / 60)} mins ${singleCall.callDuration % 60} sec`}
              rating={singleCall?.rating ? singleCall?.rating : 'Not rated yet'}
              comments={singleCall?.callOutcome}
            />
          ))}
          {isLoading ? " Loading... " : <Pagination count={data?.data?.meta?.pageCount} onChange={handleChange} variant="outlined" shape='rounded' page={page} />}
        </div>
      </div>
    </Layout>
  )
}

export default HistoryScreen
